import { Component, OnInit } from '@angular/core'
import {
    FormBuilder,
    Validators,
    FormControl,
    FormGroup,
    ValidatorFn,
    AbstractControl,
    ValidationErrors,
    ReactiveFormsModule,
} from '@angular/forms'
import { Router } from '@angular/router'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { tapResponse } from '@ngrx/operators'
import { select, Store } from '@ngrx/store'
import {
    BehaviorSubject,
    catchError,
    forkJoin,
    map,
    Observable,
    of,
    take,
    tap,
    throwError,
} from 'rxjs'
import { isUserLoggedInSelector } from 'src/app/auth/store/selectors'
import {
    StepDirection,
    StepEvent,
    Steps,
    stepTransitions,
} from 'src/app/masjids/interfaces/masjid.form.steps.interface'
import { MasjidStateInterface } from 'src/app/masjids/interfaces/masjid.state.interface'
import {
    MasjidDataInterface,
    MasjidLessonsInterface,
} from 'src/app/masjids/interfaces/masjidData.interface'
import { CreateMasjidStore } from 'src/app/masjids/store/states/create.masjid.state'
import { Country } from 'src/app/shared/interfaces/country.interface'
import {
    PhotoInterface,
    PhotoType,
} from 'src/app/shared/interfaces/Image.interface'
import { AuthService } from 'src/app/shared/services/auth.service'
import { FileStorageService } from 'src/app/shared/services/file-storage.service'
import { MasjidDocumentService } from 'src/app/shared/services/masjid.service'
import Utils from 'src/app/shared/services/utils'
import { environment } from 'src/environments/environment'
import {
    registerMasjid,
    registerMasjidFailure,
} from '../../store/actions/masjid.actions'
import { NotificationService } from 'src/app/shared/services/notification.service'
import { CommonModule } from '@angular/common'
import { MatCardModule } from '@angular/material/card'
import { MatLabel, MatFormFieldModule } from '@angular/material/form-field'
import { BasicInformationComponent } from './basic-information/basic-information.component'
import { MasjidAddressComponent } from './masjid-address/masjid-address.component'
import { MasjidContactsComponent } from './masjid-contacts/masjid-contacts.component'
import { MasjidLeaderComponent } from './masjid-leader/masjid-leader.component'
import { MasjidOrganizationComponent } from './masjid-organization/masjid-organization.component'
import { MasjidLessonsComponent } from './masjid-lessons/masjid-lessons.component'
import { MatButtonModule } from '@angular/material/button'
import { TranslocoModule } from '@ngneat/transloco'
import { MatInputModule } from '@angular/material/input'
@UntilDestroy()
@Component({
    selector: 'app-create-masjid',
    templateUrl: './create-masjid.component.html',
    styleUrls: ['./create-masjid.component.scss'],
    standalone: true,
    imports: [
        MatCardModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        CommonModule,
        BasicInformationComponent,
        MasjidAddressComponent,
        MasjidContactsComponent,
        MasjidLeaderComponent,
        MasjidOrganizationComponent,
        MasjidLessonsComponent,
        TranslocoModule,
        MatButtonModule,
        MatInputModule,
    ],
})
export class CreateMasjidComponent implements OnInit {
    constructor(
        private _fb: FormBuilder,
        private _authService: AuthService,
        private _componentStore: CreateMasjidStore,
        private _store: Store,
        private _storageService: FileStorageService,
        private _masjidService: MasjidDocumentService,
        private _notificationService: NotificationService,
        private _router: Router
    ) {
        this.backendError$ = _componentStore.backendError$
        this.isSubmittingSave$ = _componentStore.isSubmittingSave$
        this._store
            .select((state) => state)
            .pipe(
                select(isUserLoggedInSelector),
                tap((state) => {
                    console.log(
                        'user is logged in from the create masjid page',
                        state
                    )
                    this.isUserLoggedIn = state
                }),
                untilDestroyed(this)
            )
            .subscribe()

        this._authService
            .isAuthenticated()
            .pipe(
                take(1),
                tapResponse(
                    (user) => {
                        console.log(user)
                        if (user.auth.currentUser != null)
                            this.userId = user.auth.currentUser.userID!

                        console.log(
                            'user is logged in',
                            this.isUserLoggedIn,
                            this.userId
                        )
                    },
                    (error: Error) => console.error(error.message)
                ),
                untilDestroyed(this)
            )
            .subscribe()
    }

    ngOnInit(): void {
        console.log('initiating form BasicInfo')
        this._authService
            .getCountries()
            .pipe(take(1), untilDestroyed(this))
            .subscribe((countries) => {
                this.countries = countries
            })
        this.masjidForm = this._fb.group({
            basicInfo: this._fb.group(
                {
                    name: [
                        '',
                        [
                            Validators.required,
                            Validators.minLength(5),
                            Validators.maxLength(100),
                        ],
                    ],
                    description: ['', [Validators.maxLength(2000)]],
                    email: ['', [Validators.email]],
                    openHour: [null, [Validators.required]],
                    closeHour: [null, [Validators.required]],
                },
                { validators: this.validTime }
            ),
            addressInfo: this._fb.group({
                street: [
                    '',
                    [
                        Validators.required,
                        Validators.pattern(
                            '^([0-9]+[ ]?)?([a-zA-Z]+[ ]?){0,}?'
                        ),
                    ],
                ],
                houseNumber: [
                    '',
                    [
                        Validators.required,
                        Validators.pattern('^([0-9]+)[ ]?([a-zA-Z]{1,3})?'),
                    ],
                ],
                country: ['', [Validators.required, this.countryValidator]],
                city: [
                    '',
                    [
                        Validators.required,
                        Validators.pattern(
                            "^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$"
                        ),
                    ],
                ],
                state: [
                    '',
                    [
                        Validators.required,
                        Validators.pattern(
                            "^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$"
                        ),
                    ],
                ],
                postalCode: [
                    '',
                    [Validators.required, Validators.maxLength(10)],
                ],
            }),
            contactInfo: this._fb.group({
                telephone: [null],
                mobile: [null],
                whatsapp: [null],
                facebookUrl: [
                    '',
                    [
                        Validators.pattern(
                            '(?:(?:http|https)://)?(?:www.)?(?:facebook.com|instagr.am|twitter.com)/([A-Za-z0-9-_.]+)'
                        ),
                    ],
                ],
                instagramUrl: [
                    '',
                    [
                        Validators.pattern(
                            '(?:(?:http|https)://)?(?:www.)?(?:instagram.com|instagr.am|twitter.com)/([A-Za-z0-9-_.]+)'
                        ),
                    ],
                ],
            }),
            leaderInfo: this._fb.group({
                leaderName: ['', [Validators.minLength(5)]],
                leaderDescription: ['', [Validators.minLength(10)]],
            }),
            NGOInfo: this._fb.group({
                NgoName: ['', [Validators.minLength(5)]],
                NgoTelephone: [null],
                NgoMobile: [null],
                NgoEmail: ['', [Validators.email]],
            }),
            lessonsInfo: this._fb.group({
                lessonName: ['', [Validators.minLength(5)]],
                lessonLanguage: ['', [Validators.minLength(5)]],
                lessonDuration: [null],
                lessonStart: new FormControl('00:00', [Validators.required]),
                lessonEnd: new FormControl('00:00', [Validators.required]),
                lessonInstructor: ['', [Validators.minLength(5)]],
                lessonDays: ['', this.atLeastNDaysSelected],
                lessonId: [''],
                masjid_id: [''],
            }),
        })

        // Set default values for time fields
        this.masjidForm.get('basicInfo.openHour')?.setValue(null)
        this.masjidForm.get('basicInfo.closeHour')?.setValue(null)

        this._componentStore.state$
            .pipe(untilDestroyed(this))
            .subscribe((value) => console.log('state has changed', value))
    }
    private currentStepBs: BehaviorSubject<Steps> = new BehaviorSubject<Steps>(
        Steps.BasicInfo
    )
    currentStep$: Observable<Steps> = this.currentStepBs.asObservable()
    masjidForm: FormGroup
    countries: Country[] = []
    backendError$: Observable<string | null>
    isSubmittingSave$: Observable<boolean>
    masjid$: Observable<MasjidStateInterface | null>
    isUserLoggedIn: boolean
    progress$: Observable<number> = of(0)
    masjidBucketId: string = environment.masjid_images_bucket
    leaderBucketId: string = environment.masjid_leader_images_bucket
    masjidImages: PhotoInterface[] = []
    userId: string
    submitForm: boolean = false
    masjidLessons: MasjidLessonsInterface[] = []

    changeStep(event: StepEvent): void {
        const { currentStep, direction } = event

        // Handle invalid form group for current step
        if (
            direction === StepDirection.Next &&
            !this.isStepValid(currentStep)
        ) {
            console.warn(`Current step '${currentStep}' is invalid.`)
            return
        }
        if (direction === StepDirection.Back) {
            if (currentStep === Steps.BasicInfo) {
                this.masjidImages = []; // Clear masjid images when going back
            }
            if (currentStep === Steps.LeaderInfo) {
                this.masjidImages = this.masjidImages.filter(
                    (file) => file.photoType !== PhotoType.Leader
                ); // Clear only leader images
            }
        }
        // Determine the next step based on direction
        const nextStep = this.getNextStep(currentStep, direction)

        if (!nextStep && direction !== StepDirection.Finish) {
            console.warn(
                `No valid transition for '${currentStep}' with direction '${direction}'.`
            )
            return
        }

        // Save current step data
        this.saveCurrentStepData(currentStep)

        if (direction === StepDirection.Finish) {
            console.log('Submitting the form...')
            this.submitMasjidForm()
        } else if (nextStep) {
            console.log(`Transitioning from '${currentStep}' to '${nextStep}'.`)
            this.currentStepBs.next(nextStep)
        }
    }

    /**
     * Validates the current step form group.
     * @param step The current step to validate.
     * @returns Whether the step is valid.
     */
    private isStepValid(step: Steps): boolean {
        const formGroup = this.masjidForm.get(step)
        if (formGroup && !formGroup.valid) {
            formGroup.markAllAsTouched()
            return false
        }
        return true
    }

    /**
     * Retrieves the next step based on the current step and direction.
     * @param currentStep The current step.
     * @param direction The direction of the transition.
     * @returns The next step, or `null` if no transition is available.
     */
    private getNextStep(
        currentStep: Steps,
        direction: StepDirection
    ): Steps | null {
        if (
            direction === StepDirection.Next ||
            direction === StepDirection.Back
        ) {
            return stepTransitions[currentStep]?.[direction] ?? null
        }
        return null
    }

    /**
     * Saves the data for the current step.
     * @param currentStep The step to save data for.
     */
    private saveCurrentStepData(currentStep: Steps): void {
        const formGroup = this.masjidForm.get(currentStep)
        if (formGroup) {
            this._componentStore.updateMasjidData(
                formGroup.value as MasjidDataInterface
            )
            console.log(`Data saved for step '${currentStep}'.`)
        } else {
            console.warn(`No form group found for step '${currentStep}'.`)
        }
    }

    submitMasjidForm(): void {
        const newId = Utils.generateUniqueId()
        // se masjid_id for each lesson item with new generated id
        this.masjidLessons.forEach((lesson) => {
            lesson.masjid_id = newId
        })

        this._componentStore.updateMasjidData({
            id: newId,
            administratorsIds: [this.userId!],
            lessons: this.masjidLessons,
        } as MasjidDataInterface)

        const masjidData: MasjidDataInterface = {
            id: newId,
            ...this.masjidForm.controls['basicInfo'].value,
            ...this.masjidForm.controls['addressInfo'].value,
            ...this.masjidForm.controls['contactInfo'].value,
            ...this.masjidForm.controls['leaderInfo'].value,
            ...this.masjidForm.controls['NGOInfo'].value,
            ImagesIds: [],
            administratorsIds: [this.userId!],
            lessons: this.masjidLessons,
        }
        console.log('masjid data...', masjidData)

        // Get photos from the ComponentStore and upload them
        this._componentStore
            .select((state) => state.masjidPhotos)
            .pipe(take(1))
            .subscribe((photos) => {
                console.log('Photos retrieved from ComponentStore:', photos)
                this._store.dispatch(registerMasjid({
                    masjidData,
                    filesToUpload: photos, 
                    lessons: this.masjidLessons
                }));
            })
        this.masjidLessons = []
        this.masjidImages = [];
    }

    uploadFiles(
        photos: PhotoInterface[]
    ): Observable<{ masjidPhotoIds: string[]; leaderPhotoId: string | null }> {
        if (!photos || photos.length === 0) {
            return of({ masjidPhotoIds: [], leaderPhotoId: null })
        }

        return forkJoin(
            photos.map((photo) =>
                this._storageService
                    .uploadFile(photo.photo, photo.id, photo.bucketId)
                    .pipe(
                        catchError((error) => {
                            console.error(
                                `Failed to upload photo: ${photo.name}`,
                                error
                            )
                            throw error // Stop the process and propagate error
                        })
                    )
            )
        ).pipe(
            map((uploadedFiles) => {
                // Use the utility method for filtering photo IDs
                const masjidPhotoIds = Utils.mapUploadedPhotosToIds(
                    uploadedFiles,
                    photos,
                    PhotoType.Masjid
                )

                const leaderPhotoId =
                    Utils.mapUploadedPhotosToIds(
                        uploadedFiles,
                        photos,
                        PhotoType.Leader
                    )[0] || null

                return { masjidPhotoIds, leaderPhotoId }
            }),
            catchError((error) => {
                console.error('Error during file uploads:', error)
                return throwError(() => error)
            })
        )
    }

    setMasjidLeaderImage(files: PhotoInterface[]) {
        console.log('main files received data from basic info', files)

        // Remove files with type Leader and add new files
        this.masjidImages = [
            ...this.masjidImages.filter(
                (file) => file.photoType !== PhotoType.Leader
            ),
            ...files,
        ]

        console.log(
            'Photos array masjidImages in LEADER component',
            this.masjidImages
        )

        this._componentStore.updateMasjidPhotos(this.masjidImages)
    }

    setMasjidImages(files: PhotoInterface[]) {
        console.log('Main files received data from basic info:', files)
        // Remove files with type Masjid and add new files
        this.masjidImages = [
            ...this.masjidImages.filter(
                (file) => file.photoType !== PhotoType.Masjid
            ),
            ...files,
        ]

        console.log(
            'Photos array masjidImages in BASICINFO component',
            this.masjidImages
        )

        this._componentStore.updateMasjidPhotos(this.masjidImages)
    }

    setMasjidLessons(lessons: MasjidLessonsInterface[]) {
        console.log('Main files received data from lessons info:', lessons)
        this.masjidLessons = lessons
    }

    countryValidator(countries: Country[]): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (!control.value) {
                return { countryValidator: true }
            }
            const isValid = countries.some(
                (country) =>
                    country.name.toLowerCase() === control.value.toLowerCase()
            )
            return isValid ? null : { countryValidator: true }
        }
    }

    validTime(group: AbstractControl): ValidationErrors | null {
        const openHour = group.get('openHour')
        const closeHour = group.get('closeHour')

        if (!openHour || !closeHour) return null // Skip if controls don't exist

        const openHourValue = openHour.value
        const closeHourValue = closeHour.value

        // Only validate the time relationship if both fields are filled
        if (
            openHourValue &&
            closeHourValue &&
            openHourValue >= closeHourValue
        ) {
            group.setErrors({ timeInvalid: true })
            return { timeInvalid: true }
        }

        // Clear the group-level error if the condition no longer applies
        if (group.hasError('timeInvalid')) {
            group.setErrors(null)
        }

        return null // No errors
    }

    public atLeastNDaysSelected: ValidatorFn = (
        control: AbstractControl
    ): ValidationErrors | null => {
        const selectedDays = control.value
        const minimumSelectedDays = 1

        if (!selectedDays || selectedDays.length < minimumSelectedDays) {
            return { atLeastNDays: true }
        }

        return null
    }
}
