import { Inject, Injectable } from '@angular/core'
import { AppwriteException, Models, Query } from 'appwrite'
import { Observable, catchError, from, throwError } from 'rxjs'
import { MasjidDataInterface } from 'src/app/masjids/interfaces/masjidData.interface'
import {
    APPWRITE_SDK,
    AppwriteSdk,
} from 'src/app/shared/services/appwrite.provider'
import { environment } from 'src/environments/environment'
import { LogType } from '../interfaces/log.interface'
import { LogService } from './log.service'
import Utils from './utils'

@Injectable({
    providedIn: 'root',
})
export class MasjidDocumentService {
    constructor(
        @Inject(APPWRITE_SDK) private _appwrite: AppwriteSdk,
        private _logService: LogService
    ) {}

    getMasjidsByAdminId(adminId: string): Observable<MasjidDataInterface[]> {
        return from(
            this._appwrite.databases
                .listDocuments(
                    environment.main_database_id,
                    environment.masjid_data_collection_id,
                    [
                        Query.contains('administrators_Ids', [adminId])
                    ]
                )
                .then((documents) => {
                    return documents.documents.map((doc) =>
                        this.convertDocumentToInterface(doc)
                    );
                })
        ).pipe(
            catchError((error) => this.handleErrors(error))
        );
    }
    
    getMasjidDocumentById(masjidId: string): Observable<MasjidDataInterface> {
        return from(
            this._appwrite.databases
                .getDocument(
                    environment.main_database_id,
                    environment.masjid_data_collection_id,
                    masjidId
                )
                .then((document) => {
                    return this.convertDocumentToInterface(document)
                })
        ).pipe(
            catchError((error) => {
                return this.handleErrors(error)
            })
        )
    }

    updateMasjidDocument(
        masjidId: string,
        data: { [key: string]: any } | MasjidDataInterface
    ): Observable<Models.Document> {
        return from(
            this._appwrite.databases
                .updateDocument(
                    environment.main_database_id,
                    environment.masjid_data_collection_id,
                    masjidId,
                    {
                        name: data.name,
                        description: data.description,
                        openHour:
                            Number(data.openHour) > 0
                                ? String(data.openHour)
                                : '0',
                        closeHour:
                            Number(data.closeHour) > 0
                                ? String(data.closeHour)
                                : '0',
                        email: data.email,
                        facebookUrl: data.facebookUrl,
                        instagramUrl: data.instagramUrl,
                        whatsapp: data.whatsapp,
                        telephone: data.telephone,
                        mobile: data.mobile,
                        country: data.country,
                        state: data.state,
                        city: data.city,
                        street: data.street,
                        post_code: data.postalCode,
                        house_number: data.houseNumber,
                        leader_name: data.leaderName,
                        leader_description: data.leaderDescription,
                        leader_imageId: data.leaderImageId,
                        ngo_name: data.NgoName,
                        ngo_telephone: data.NgoTelephone,
                        ngo_mobile: data.NgoMobile,
                        ngo_email: data.NgoEmail,
                        photos_Ids: data.ImagesIds,
                        administrators_Ids: data.administratorsIds,
                        lessons_Ids: data.lessonIds,
                    }
                )
                .then((value) => {
                    return value
                })
        ).pipe(
            catchError((error) => {
                return this.handleErrors(error)
            })
        )
    }

    createMasjidDocument(
        masjidData: MasjidDataInterface
    ): Observable<Models.Document> {
        return from(
            this._appwrite.databases
                .createDocument(
                    environment.main_database_id,
                    environment.masjid_data_collection_id,
                    masjidData.id,
                    {
                        name: masjidData.name,
                        description: masjidData.description,
                        openHour:
                            Number(masjidData.openHour!) > 0
                                ? String(masjidData.openHour)
                                : '0',
                        closeHour:
                            Number(masjidData.closeHour!) > 0
                                ? String(masjidData.closeHour)
                                : '0',
                        email: masjidData.email,
                        facebookUrl: masjidData.facebookUrl,
                        instagramUrl: masjidData.instagramUrl,
                        whatsapp: masjidData.whatsapp,
                        telephone: masjidData.telephone,
                        mobile: masjidData.mobile,
                        country: masjidData.country,
                        state: masjidData.state,
                        city: masjidData.city,
                        street: masjidData.street,
                        post_code: masjidData.postalCode,
                        house_number: masjidData.houseNumber,
                        leader_name: masjidData.leaderName,
                        leader_description: masjidData.leaderDescription,
                        leader_imageId: masjidData.leaderImageId,
                        ngo_name: masjidData.NgoName,
                        ngo_telephone: masjidData.NgoTelephone,
                        ngo_mobile: masjidData.NgoMobile,
                        ngo_email: masjidData.NgoEmail,
                        photos_Ids: masjidData.ImagesIds,
                        administrators_Ids: masjidData.administratorsIds,
                        lessons_Ids: masjidData.lessonIds,
                    }
                )
                .then((value) => {
                    return value
                })
        ).pipe(
            catchError((error) => {
                return this.handleErrors(error)
            })
        )
    }

    getMasjidList(): Observable<MasjidDataInterface[]> {
        return from(
            this._appwrite.databases
                .listDocuments(
                    environment.main_database_id,
                    environment.masjid_data_collection_id
                )
                .then((documents) => {
                    console.log(
                        'total number of masjids registered and is going to be fetched from database',
                        documents.total
                    )
                    let list: MasjidDataInterface[] = []
                    documents.documents.forEach((doc) => {
                        list.push(this.convertDocumentToInterface(doc))
                    })
                    return list
                })
        ).pipe(
            catchError((error) => {
                return this.handleErrors(error)
            })
        )
    }

    handleErrors(error: Error | AppwriteException): Observable<never> {
        let message = Utils.handleErrorMessage(error)
        this._logService.writeLogAsync({
            message: message,
            logType: LogType.Error,
        })
        return throwError(() => error)
    }

    convertDocumentToInterface(document: Models.Document): MasjidDataInterface {
        return {
            id: document.$id,
            name: document['name'],
            description: document['description'],
            openHour: document['openHour'],
            closeHour: document['closeHour'],
            email: document['email'],
            facebookUrl: document['facebookUrl'],
            instagramUrl: document['instagramUrl'],
            whatsapp: document['whatsapp'],
            telephone: document['telephone'],
            mobile: document['mobile'],
            country: document['country'],
            state: document['state'],
            city: document['city'],
            street: document['street'],
            postalCode: document['post_code'],
            houseNumber: document['house_number'],
            leaderName: document['leader_name'],
            leaderDescription: document['leader_description'],
            leaderImageId: document['leader_imageId'],
            NgoName: document['ngo_name'],
            NgoTelephone: document['ngo_telephone'],
            NgoMobile: document['ngo_mobile'],
            NgoEmail: document['ngo_email'],
            ImagesIds: Array.from(document['photos_Ids'] || []),
            administratorsIds: Array.from(document['administrators_Ids'] || []),
            lessonIds: Array.from(document['lessons_Ids'] || []),
            lessons: [],
        }
    }
}
