import { CdkDragDrop, DragDropModule, moveItemInArray } from '@angular/cdk/drag-drop'
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout'
import { MatTableDataSource, MatTableModule } from '@angular/material/table'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { Store, select } from '@ngrx/store'
import { AppwriteException } from 'appwrite'
import { NgxMaterialTimepickerModule, NgxMaterialTimepickerTheme, NgxTimepickerFieldComponent } from 'ngx-material-timepicker'
import { of, throwError } from 'rxjs'
import { Observable } from 'rxjs/internal/Observable'
import {
    catchError,
    finalize,
    map,
    shareReplay,
    startWith,
    switchMap,
    take,
} from 'rxjs/operators'
import {
    MasjidDataInterface,
    MasjidLessonsInterface,
} from 'src/app/masjids/interfaces/masjidData.interface'
import {
    PhotoInterface,
    PhotoType,
} from 'src/app/shared/interfaces/Image.interface'
import { Country } from 'src/app/shared/interfaces/country.interface'
import { LogType } from 'src/app/shared/interfaces/log.interface'
import { AuthService } from 'src/app/shared/services/auth.service'
import { FileStorageService } from 'src/app/shared/services/file-storage.service'
import { LessonsService } from 'src/app/shared/services/lessons.service'
import { LogService } from 'src/app/shared/services/log.service'
import { MasjidDocumentService } from 'src/app/shared/services/masjid.service'
import { NotificationService } from 'src/app/shared/services/notification.service'
import Utils from 'src/app/shared/services/utils'
import { AppState } from 'src/app/store/app.state'
import { environment } from 'src/environments/environment'
import { PhotoOperation } from '../../interfaces/masjid.state.interface'
import { updateExistingMasjid } from '../../store/actions/edit.masjid.actions'
import { editMasjidIsSubmittingSaveSelector } from '../../store/selectors'
import { EditMasjidStore } from '../../store/states/edit.masjid.state'
import { Component, OnInit } from '@angular/core'
import {
    FormGroup,
    FormBuilder,
    Validators,
    FormControl,
    ValidatorFn,
    AbstractControl,
    ValidationErrors,
    ReactiveFormsModule,
} from '@angular/forms'
import { Router, ActivatedRoute, RouterModule } from '@angular/router'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatCardModule } from '@angular/material/card'
import { MatSelectModule } from '@angular/material/select'
import { MatIconModule } from '@angular/material/icon'
import { MatToolbarModule } from '@angular/material/toolbar'
import { PhotoUploadComponent } from 'src/app/shared/components/file-upload/photo-upload/photo-upload.component'
import { MatInputModule } from '@angular/material/input'
import { CommonModule } from '@angular/common'
import { MatAutocomplete, MatAutocompleteModule } from '@angular/material/autocomplete'
import { MatButtonModule } from '@angular/material/button'
import { MatListModule } from '@angular/material/list'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { MatTabsModule } from '@angular/material/tabs'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatDividerModule } from '@angular/material/divider'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { TranslocoModule } from '@ngneat/transloco'

@UntilDestroy()
@Component({
    selector: 'app-edit-masjid',
    templateUrl: './edit-masjid.component.html',
    styleUrls: ['./edit-masjid.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        DragDropModule,
        ReactiveFormsModule,
        MatInputModule,
        MatFormFieldModule,
        MatButtonModule,
        MatCardModule,
        MatIconModule,
        MatTabsModule,
        MatListModule,
        MatToolbarModule,
        MatProgressBarModule,
        MatSelectModule,
        PhotoUploadComponent,
        MatAutocompleteModule,
        MatTableModule,
        TranslocoModule,
        MatDividerModule,
        MatCheckboxModule,
        MatProgressSpinnerModule,
        NgxMaterialTimepickerModule,
        RouterModule
    ],
})
export class EditMasjidComponent implements OnInit {
    masjidForm: FormGroup
    initialMasjidValues: { [key: string]: any }
    masjidLessonsInfo: FormGroup

    formHasChanges: boolean = false
    isSubmittingSave$: Observable<boolean> = of(false)
    data: MasjidDataInterface
    openHour: Array<number> = Utils.arrayRange(1, 25)
    closeHour: Array<number> = Utils.arrayRange(1, 25)
    selectedOpenHour: number
    selectedCloseHour: number
    isHandset$: Observable<boolean>
    filteredCountries?: Observable<Country[]>
    countries: Country[] = []

    images: PhotoInterface[] = []
    newMasjidImages: PhotoInterface[] = []
    masjidImagesToRemove: PhotoInterface[] = []

    leaderImage: PhotoInterface[] = []
    leaderImageToRemove: PhotoInterface[] = []
    leaderImageToAdd: PhotoInterface[] = []

    leaderBucketId: string = environment.masjid_leader_images_bucket

    lessonsToAdd: MasjidLessonsInterface[] = []
    lessonsToRemove: MasjidLessonsInterface[] = []
    lessonsToUpdate: MasjidLessonsInterface[] = []

    dataSource = new MatTableDataSource<MasjidLessonsInterface>()

    displayedColumns: string[] = [
        'name',
        'language',
        'duration',
        'start',
        'end',
        'instructor',
        'days',
        'actions',
    ]
    timepickerTheme: NgxMaterialTimepickerTheme = {
        container: {
            bodyBackgroundColor: '#fff',
            buttonColor: '#000',
        },
        dial: {
            dialBackgroundColor: '#fff',
            dialActiveColor: '#000',
        },
    }

    constructor(
        private _formBuilder: FormBuilder,
        private _masjidService: MasjidDocumentService,
        private _notificationService: NotificationService,
        private _router: Router,
        private _route: ActivatedRoute,
        private _authService: AuthService,
        private _fileService: FileStorageService,
        private breakpointObserver: BreakpointObserver,
        private _logService: LogService,
        private _lessonsService: LessonsService,
        private _componentStore: EditMasjidStore,
        private _store: Store<AppState>
    ) {
        this.isHandset$ = this.breakpointObserver
            .observe(Breakpoints.Handset)
            .pipe(
                map((result) => {
                    return result.matches
                }),
                shareReplay(),
                untilDestroyed(this)
            )
        // this.isSubmittingSave$ = _componentStore.isSubmittingSave$
        this.isSubmittingSave$ = this._store
            .pipe(select(editMasjidIsSubmittingSaveSelector))
    }

    ngOnInit(): void {
        this._notificationService.loading()
        this.initForm()
        this._route.paramMap
            .pipe(
                switchMap((params) => {
                    const masjidId = params.get('masjidId')
                    if (!masjidId) {
                        this._router.navigate(['/pageNotFound'])
                        throw new Error('Masjid ID not provided')
                    }
                    return this._masjidService.getMasjidDocumentById(masjidId)
                }),
                switchMap((masjidData) => {
                    return this._lessonsService
                        .getLessonsByMasjidId(masjidData.id)
                        .pipe(map((lessons) => ({ lessons, masjidData })))
                }),
                switchMap(({ lessons, masjidData }) => {
                    console.log('getting lessons data', lessons)
                    masjidData.lessons = lessons
                    this.handleData(masjidData)
                    masjidData.ImagesIds?.forEach((id: string) => {
                        // Call another service method to get image URLs
                        const img = {
                            id: id,
                            bucketId: environment.masjid_images_bucket,
                            photoType: PhotoType.Masjid,
                            dataUrl: this._fileService.getFilePreviewUrl(
                                environment.masjid_images_bucket,
                                id
                            ),
                        } as PhotoInterface
                        this.images.push(img)
                    })
                    this.leaderImage.push({
                        id: masjidData.leaderImageId,
                        bucketId: environment.masjid_leader_images_bucket,
                        photoType: PhotoType.Leader,
                        dataUrl: this._fileService.getFilePreviewUrl(
                            environment.masjid_leader_images_bucket,
                            masjidData.leaderImageId!!
                        ),
                    } as PhotoInterface)
                    this._notificationService.removeLoading()

                    return this.images
                }),
                catchError((error: any) => {
                    this.handleError(error, 'Error while loading masjid data.')
                    return throwError(() => error)
                }),
                untilDestroyed(this)
            )
            .subscribe()
        this._componentStore.state$
            .pipe(untilDestroyed(this))
            .subscribe((updatedState) => {
                console.info('subscribe log:', updatedState)
            })
    }

    initForm() {
        this.masjidForm = this._formBuilder.group(
            {
                name: [
                    '',
                    [
                        Validators.required,
                        Validators.minLength(5),
                        Validators.maxLength(100),
                    ],
                ],
                description: ['', [Validators.maxLength(500)]],
                email: ['', [Validators.email]],
                openHour: [null, [Validators.required]],
                closeHour: [null, [Validators.required]],

                telephone: [null],
                mobile: [null],
                whatsapp: [null],
                facebookUrl: [
                    '',
                    [
                        Validators.pattern(
                            '(?:(?:http|https)://)?(?:www.)?(?:facebook.com|instagram|twitter.com)/([A-Za-z0-9-_.]+)'
                        ),
                    ],
                ],
                instagramUrl: [
                    '',
                    [
                        Validators.pattern(
                            '(?:(?:http|https)://)?(?:www.)?(?:instagram.com|instagram|twitter.com)/([A-Za-z0-9-_.]+)'
                        ),
                    ],
                ],

                street: [
                    '',
                    [
                        Validators.required,
                        Validators.pattern(
                            '^([0-9]+[ ]?)?([a-zA-Z]+[ ]?){0,}?'
                        ),
                    ],
                ],
                houseNumber: [
                    '',
                    [
                        Validators.required,
                        Validators.pattern('^([0-9]+)[ ]?([a-zA-Z]{1,3})?'),
                    ],
                ],
                country: ['', [Validators.required, this.countryValidator]],
                city: [
                    '',
                    [
                        Validators.required,
                        Validators.pattern(
                            "^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$"
                        ),
                    ],
                ],
                state: [
                    '',
                    [
                        Validators.required,
                        Validators.pattern(
                            "^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$"
                        ),
                    ],
                ],
                postalCode: [
                    '',
                    [Validators.required, Validators.maxLength(10)],
                ],

                leaderName: ['', [Validators.minLength(5)]],
                leaderDescription: ['', [Validators.minLength(10)]],

                NgoName: ['', [Validators.minLength(5)]],
                NgoTelephone: [null],
                NgoMobile: [null],
                NgoEmail: ['', [Validators.email]],

                leaderImageId: [''],
                ImagesIds: [''],
                administratorsIds: [''],
            },
            { validators: this.validTime }
        )
        this.masjidLessonsInfo = this._formBuilder.group({
            lessonName: ['', [Validators.minLength(5)]],
            lessonLanguage: ['', [Validators.minLength(5)]],
            lessonDuration: [null],
            lessonStart: new FormControl('00:00', [Validators.required]),
            lessonEnd: new FormControl('00:00', [Validators.required]),
            lessonInstructor: ['', [Validators.minLength(5)]],
            lessonDays: ['', this.atLeastNDaysSelected],
            lessonId: [''],
            masjid_id: [''],
        })
    }

    handleData(data: MasjidDataInterface): void {
        this.data = data

        console.log('Received data:', data) // Log to verify the received data
        if (this.data) {
            this.masjidForm.patchValue(this.data)
            this._componentStore.setMasjidData(this.data)

            this.dataSource.data = this.data.lessons
            this.selectedOpenHour = Number(
                this.masjidForm.get('openHour')?.value
            )
            this.selectedCloseHour = Number(
                this.masjidForm.get('closeHour')?.value
            )

            this._authService
                .getCountries()
                .pipe(take(1), untilDestroyed(this))
                .subscribe((countries) => {
                    this.countries = countries
                })
            this.filteredCountries = this.masjidForm
                .get('country')
                ?.valueChanges.pipe(
                    startWith(''),
                    map((value) =>
                        Utils.filterCountries(value || '', this.countries)
                    ),
                    untilDestroyed(this)
                )
        }

        this.initialMasjidValues = this.masjidForm.value
        console.log('initialMasjidValues from:', this.initialMasjidValues)
    }

    handleError(error: any, errorMessage: string): void {
        this._notificationService.removeLoading()
        let message: string
        if (error instanceof AppwriteException) {
            message = Utils.formatAppWriteException(error)
        } else message = Utils.formatException(error)
        this._logService.writeLogAsync({
            message: message,
            logType: LogType.Error,
        })
        this._notificationService.notifyFailure(errorMessage)
    }

    updateMasjid(): void {
        console.log('starting form submission')
        let uploadObservables: Observable<{
            operation: string
            imageType: PhotoType
            id: string
        }>[] = []
        let deleteObservables: Observable<{
            operation: string
            imageType: PhotoType
            id: string
        }>[] = []
        this._componentStore.resetFiles()
        // Step 1: Upload masjid images
        if (this.newMasjidImages.length) {
            let newImages = this.uploadNewMasjidImages(this.newMasjidImages)
            if (newImages != null) uploadObservables.push(...newImages)
            this.newMasjidImages = []
        }
        // upload leader masjid image if its changed
        if (this.leaderImageToAdd.length) {
            let newLeaderImage = this.uploadLeaderImage(
                this.leaderImageToAdd[0]
            )
            uploadObservables.push(newLeaderImage)
            this.leaderImageToAdd = []
        }

        // Step 2: Delete masjid images
        if (this.masjidImagesToRemove.length) {
            let images = this.deleteMasjidImages(this.masjidImagesToRemove)
            if (images != null) deleteObservables.push(...images)
            this.masjidImagesToRemove = []
        }
        // delete masjid leader image
        if (this.leaderImageToRemove.length) {
            let leaderImage = this.deleteLeaderImage(
                this.leaderImageToRemove[0].id!!
            )
            if (leaderImage != null) deleteObservables.push(leaderImage)
            this.leaderImageToRemove = []
        }

        //updating the state
        this._componentStore.setDeleteFiles(deleteObservables)
        this._componentStore.setUploadFiles(uploadObservables)

        uploadObservables = []
        deleteObservables = []

        this._componentStore.setLessons({
            newLessons: this.lessonsToAdd,
            updateLessons: this.lessonsToUpdate,
            deleteLessons: this.lessonsToRemove,
        })
        console.log('this.initialMasjidValues', this.initialMasjidValues)
        console.log('this.masjidForm', this.masjidForm.value)
        this.formHasChanges = Utils.checkForFormChanges(
            this.initialMasjidValues,
            this.masjidForm
        )
        console.log('form has changes is:', this.formHasChanges)

        if (this.formHasChanges) {
            console.log(
                'form has values changed and needs to update the masjid data'
            )
            const fieldsValues = Utils.getDirtyFields(this.masjidForm)
            console.log('fieldsValues:', fieldsValues)

            this._componentStore.setMasjidData(fieldsValues)
        }
        this._componentStore.setIsSubmittingSave(true)
        console.log('updateExistingMasjid action triggered')

        this._store.dispatch(updateExistingMasjid())
    }

    getImagesFromChildForm(files: PhotoInterface[]) {
        console.log('masjid leader image changed to:', files[0])

        let img = files[0]
        img.id = Utils.generateUniqueId()
        //get the old image to delete later
        this.leaderImageToRemove.push(this.leaderImage[0])
        this.leaderImage = []
        this.leaderImage.push(img)
        this.leaderImageToAdd.push(img)
        console.log(
            'leader image the same of the old image leaderImageToAdd',
            this.leaderImageToAdd
        )
    }

    //#region lessons table functions
    clearTable() {
        this.dataSource.data = []
        this.lessonsToAdd = []
        this.lessonsToRemove = []
        this.lessonsToUpdate = []
    }

    removeItem(row: MasjidLessonsInterface) {
        const index = this.dataSource.data.indexOf(row)
        if (index >= 0) {
            const newData = [
                ...this.dataSource.data.slice(0, index),
                ...this.dataSource.data.slice(index + 1),
            ]
            this.dataSource.data = newData
            this.dataSource._updateChangeSubscription()
        }
        this.lessonsToRemove.push(row)
        console.log('lesson to remove', this.lessonsToRemove)
    }

    addToTable() {
        if (this.masjidLessonsInfo.valid) {
            console.log(
                'this.masjidLessonsInfo.value is ',
                this.masjidLessonsInfo.value
            )
            let updatedLessons: MasjidLessonsInterface[]

            const newLesson = {
                lessonId:
                    this.masjidLessonsInfo.value.lessonId ||
                    Utils.generateUniqueId(),
                name: this.masjidLessonsInfo.value.lessonName,
                start: this.masjidLessonsInfo.value.lessonStart,
                end: this.masjidLessonsInfo.value.lessonEnd,
                language: this.masjidLessonsInfo.value.lessonLanguage,
                instructor: this.masjidLessonsInfo.value.lessonInstructor,
                duration: this.masjidLessonsInfo.value.lessonDuration,
                masjid_id: this.data.id,
                days: this.masjidLessonsInfo.value.lessonDays.map(
                    (day: string) => day.trim()
                ),
            }

            const existingLessonIndex = this.data.lessons.findIndex(
                (lesson) => lesson.lessonId === newLesson.lessonId
            )
            console.log('existing Lesson Index is ', existingLessonIndex)

            if (existingLessonIndex !== -1) {
                updatedLessons = this.data.lessons.map((lesson, index) =>
                    index === existingLessonIndex ? newLesson : lesson
                )
                this.lessonsToUpdate.push(newLesson)
                console.log('lessons to update is ', this.lessonsToUpdate)
            } else {
                updatedLessons = [...this.data.lessons, newLesson]
                this.lessonsToAdd.push(newLesson)
                console.log('lessons to add is ', this.lessonsToAdd)
            }
            console.log('updated list updatedLessons:', updatedLessons)
            this.data = { ...this.data, lessons: updatedLessons }
            this.dataSource.data = updatedLessons
            this.dataSource._updateChangeSubscription()
            this.masjidLessonsInfo.reset()
        } else console.log('this.masjidLessonsInfo is ', this.masjidForm.valid)
    }

    editItem(row: MasjidLessonsInterface) {
        this.masjidLessonsInfo.patchValue({
            lessonName: row.name,
            lessonLanguage: row.language,
            lessonDuration: row.duration,
            lessonStart: row.start,
            lessonEnd: row.end,
            lessonInstructor: row.instructor,
            lessonDays: row.days,
            masjid_id: row.masjid_id,
            lessonId: row.lessonId,
        })
        this.updateDuration(row.start, 'start')
        this.updateDuration(row.end, 'end')
    }

    updateDuration(timeUpdated: string, action: string) {
        if (action == 'end')
            this.masjidLessonsInfo.patchValue(
                { lessonEnd: timeUpdated },
                { emitEvent: false }
            )
        if (action == 'start')
            this.masjidLessonsInfo.patchValue(
                { lessonStart: timeUpdated },
                { emitEvent: false }
            )

        console.log(`time picked for ${action} time:`, timeUpdated)
        const start = this.masjidLessonsInfo.get('lessonStart')!.value
        const end = this.masjidLessonsInfo.get('lessonEnd')!.value

        if (start && end) {
            const startTime = new Date(`2000-01-01T${start}`)
            const endTime = new Date(`2000-01-01T${end}`)
            const duration = new Date(endTime.getTime() - startTime.getTime())

            // Format the duration as HH:mm
            const hours = duration.getUTCHours().toString().padStart(2, '0')
            const minutes = duration.getUTCMinutes().toString().padStart(2, '0')
            const formattedDuration = `${hours}:${minutes}`

            this.masjidLessonsInfo.patchValue(
                { lessonDuration: formattedDuration },
                { emitEvent: false }
            )
        }
    }
    //#endregion

    //#region general functions
    private deleteLeaderImage(
        imageId: string
    ): Observable<PhotoOperation> | null {
        if (imageId !== undefined && imageId.trim() !== '') {
            console.log(`leader image to delete with id: ${imageId}`)

            const deleteObservable = this._fileService
                .deleteMasjidLeaderImg(imageId)
                .pipe(
                    map((result) => {
                        console.log(
                            `Leader Image file deleted result: ${result}`
                        )
                        return result
                    }),
                    catchError((error: any) => {
                        this.handleError(
                            error,
                            'error while deleting leader image.'
                        )
                        return throwError(() => error)
                    })
                )

            this.leaderImageToRemove = [] // Clear the array after processing
            return deleteObservable
        } else {
            return null
        }
    }
    private uploadLeaderImage(
        leaderImage: PhotoInterface
    ): Observable<PhotoOperation> {
        const uploadObservable = this._fileService
            .uploadFile(
                leaderImage.photo,
                leaderImage.id!!,
                leaderImage.bucketId
            )
            .pipe(
                map((result) => {
                    console.log(
                        `leader masjid image uploaded successfully file name: ${result.name}`
                    )

                    return {
                        operation: 'upload',
                        imageType: PhotoType.Leader,
                        id: result.$id,
                    } as PhotoOperation
                }),
                catchError((error: any) => {
                    this.handleError(
                        error,
                        'An error occurred while uploading the image'
                    )
                    return throwError(() => error)
                }),
                untilDestroyed(this),
                finalize(() =>
                    console.log('Observable completed leader masjid image')
                )
            )

        this.leaderImageToAdd = [] // Clear the array after processing
        return uploadObservable
    }

    private deleteMasjidImages(
        masjidImagesToRemove: PhotoInterface[]
    ): Observable<PhotoOperation>[] | null {
        if (masjidImagesToRemove.length) {
            const deleteObservables: Observable<PhotoOperation>[] = []

            for (let index = 0; index < masjidImagesToRemove.length; index++) {
                const photo = masjidImagesToRemove[index]

                const deleteObservable = this._fileService
                    .deleteMasjidImg(photo.id!!)
                    .pipe(
                        map((result) => {
                            if (result) {
                                console.log(
                                    `file delete result file deleted: ${result.id} of type ${result.imageType}`
                                )
                            } else {
                                console.log('file was not deleted', result)
                            }

                            return result
                        }),
                        catchError((error: any) => {
                            this.handleError(
                                error,
                                'An unexpected error occurred while deleting the image!'
                            )
                            return throwError(() => error)
                        }),
                        untilDestroyed(this)
                    )

                deleteObservables.push(deleteObservable)
            }

            console.log(`delete observables count: ${deleteObservables.length}`)
            this.masjidImagesToRemove = [] // Clear the array after processing
            return deleteObservables
        } else return null
    }

    private uploadNewMasjidImages(
        newMasjidImages: PhotoInterface[]
    ): Observable<PhotoOperation>[] | null {
        const uploadObservables: Observable<PhotoOperation>[] = []

        if (newMasjidImages.length) {
            for (let index = 0; index < newMasjidImages.length; index++) {
                const photo = newMasjidImages[index]

                const uploadObservable = this._fileService
                    .uploadFile(photo.photo, photo.id!!, photo.bucketId)
                    .pipe(
                        map((result) => {
                            console.log(
                                `file uploaded successfully file name: ${result.name}`
                            )

                            return {
                                operation: 'upload',
                                imageType: PhotoType.Masjid,
                                id: result.$id,
                            } as PhotoOperation
                        }),
                        catchError((error: any) => {
                            this.handleError(
                                error,
                                'An error occurred while uploading the image'
                            )
                            return throwError(() => error)
                        }),
                        untilDestroyed(this)
                    )

                uploadObservables.push(uploadObservable)
            }

            console.log(`upload observables count: ${uploadObservables.length}`)
            this.newMasjidImages = [] // Clear the array after processing
            return uploadObservables
        } else return null
    }
    //#endregion

    //#region form validators
    countryValidator(countries: Country[]): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (!control.value) {
                return { countryValidator: true }
            }
            const isValid = countries.some(
                (country) =>
                    country.name.toLowerCase() === control.value.toLowerCase()
            )
            return isValid ? null : { countryValidator: true }
        }
    }
    validTime(control: AbstractControl): ValidationErrors | null {
        const openHour = control.get('openHour')?.value
        const closeHour = control.get('closeHour')?.value

        if (openHour && closeHour && openHour >= closeHour) {
            control.get('openHour')?.setErrors({ timeInvalid: true })
            control.get('closeHour')?.setErrors({ timeInvalid: true })
            return { timeInvalid: true }
        } else {
            control.get('openHour')?.setErrors(null)
            control.get('closeHour')?.setErrors(null)
            return null
        }
    }
    atLeastNDaysSelected: ValidatorFn = (
        control: AbstractControl
    ): ValidationErrors | null => {
        const selectedDays = control.value
        const minimumSelectedDays = 3 // Adjust the minimum number of selected days as needed

        if (!selectedDays || selectedDays.length < minimumSelectedDays) {
            return { atLeastNDays: true }
        }

        return null
    }
    //#endregion

    //#region Drag&Drop functions
    deleteImage(position: number, image: PhotoInterface) {
        console.log(`image: ${image.name} with position: ${position}`)
        if (!this.masjidImagesToRemove.includes(image))
            this.masjidImagesToRemove.push(image)
        this.images.splice(position, 1)
        console.log('image list updated:', this.masjidImagesToRemove)
    }

    drop(event: CdkDragDrop<any[]>): void {
        moveItemInArray(this.images, event.previousIndex, event.currentIndex)
    }

    onDragOver(event: DragEvent): void {
        event.preventDefault()
        event.stopPropagation()
    }

    onDrop(event: DragEvent): void {
        event.preventDefault()
        event.stopPropagation()

        const files = event.dataTransfer?.files
        if (files && files.length > 0) {
            this.validateAndHandleFiles(files)
        }
    }

    validateAndHandleFiles(files: FileList): void {
        for (let i = 0; i < files.length; i++) {
            const file = files[i]
            if (Utils.isValidImageFile(file)) {
                const newImg = {} as PhotoInterface
                const reader = new FileReader()
                reader.onload = (e) => {
                    const imageUrl = reader.result as string
                    newImg.bucketId = environment.masjid_images_bucket
                    newImg.photoType = PhotoType.Masjid
                    newImg.name = file.name
                    newImg.photo = file
                    newImg.dataUrl = imageUrl
                    newImg.id = Utils.generateUniqueId()
                    this.images.push(newImg)
                    this.newMasjidImages.push(newImg)
                }
                reader.readAsDataURL(file)
            } else {
                console.error(
                    'Invalid file type. Please select a PNG, JPG, or JPEG image.'
                )
                this._notificationService.notifyFailure(
                    'Invalid file type File: ' +
                        file.name +
                        '. Please select a PNG, JPG, or JPEG image.'
                )
            }
        }
        console.log('images of the drag drop list:', this.images)
        console.log('newImages list:', this.newMasjidImages)
    }
    //#endregion
}
