import { Inject, Injectable } from '@angular/core'
import { tapResponse } from '@ngrx/operators'
import { AppwriteException, ID, Models, Query } from 'appwrite'
import {
    Observable,
    catchError,
    forkJoin,
    from,
    map,
    of,
    switchMap,
    throwError,
} from 'rxjs'
import { LessonOperation } from 'src/app/masjids/interfaces/masjid.state.interface'
import {
    MasjidDataInterface,
    MasjidLessonsInterface,
} from 'src/app/masjids/interfaces/masjidData.interface'
import {
    APPWRITE_SDK,
    AppwriteSdk,
} from 'src/app/shared/services/appwrite.provider'
import { environment } from 'src/environments/environment'
import { LogType } from '../interfaces/log.interface'
import { LogService } from './log.service'
import Utils from './utils'

@Injectable({
    providedIn: 'root',
})
export class LessonsService {
    constructor(
        @Inject(APPWRITE_SDK) private _appwrite: AppwriteSdk,
        private _logService: LogService
    ) {}

    public createMasjidLesson(
        lesson: MasjidLessonsInterface
    ): Observable<Models.Document> {
        return from(
            this._appwrite.databases.createDocument(
                environment.main_database_id,
                environment.masjid_lessons_collection_id,
                Utils.generateUniqueId(),
                {
                    name: lesson.name,
                    language: lesson.language,
                    duration: lesson.duration,
                    start: lesson.start,
                    end: lesson.end,
                    instructor: lesson.instructor,
                    masjid_id: lesson.masjid_id,
                    days: lesson.days,
                    lessonId: lesson.lessonId
                }
            )
        ).pipe(
            tapResponse(
                (document) => {
                    console.debug('saved lesson document!', document)
                    return document
                },
                (error: any) => {
                    return this.handleErrors(error)
                }
            )
        )
    }

    public createMasjidLessons(
        lessons: MasjidLessonsInterface[]
    ): Observable<Models.Document[]> {
        const createObservables = lessons.map((lesson) => {
            return from(
                this._appwrite.databases.createDocument(
                    environment.main_database_id,
                    environment.masjid_lessons_collection_id,
                    Utils.generateUniqueId(),
                    {
                        name: lesson.name,
                        language: lesson.language,
                        duration: lesson.duration,
                        start: lesson.start,
                        end: lesson.end,
                        instructor: lesson.instructor,
                        masjid_id: lesson.masjid_id,
                        days: lesson.days,
                        lessonId: lesson.lessonId
                    }
                )
            ).pipe(
                tapResponse(
                    (document) => {
                        console.debug('Saved lesson document!', document)
                        return document
                    },
                    (error: any) => {
                        return this.handleErrors(error)
                    }
                )
            )
        })

        return forkJoin(createObservables)
    }

    public updateMasjidLesson(
        lesson: MasjidLessonsInterface
    ): Observable<Models.Document> {
        return from(
            this._appwrite.databases
                .updateDocument(
                    environment.main_database_id,
                    environment.masjid_lessons_collection_id,
                    lesson.lessonId,
                    {
                        name: lesson.name,
                        language: lesson.language,
                        duration: lesson.duration,
                        start: lesson.start,
                        end: lesson.end,
                        instructor: lesson.instructor,
                        masjid_id: lesson.masjid_id,
                        days: lesson.days,
                    }
                )
                .then((res) => {
                    return res
                })
        ).pipe(
            tapResponse(
                (document) => {
                    console.debug('saved lesson document!', document)
                    return document
                },
                (error: any) => {
                    return this.handleErrors(error)
                }
            )
        )
    }

    public updateMasjidLessons(
        lessons: MasjidLessonsInterface[]
    ): Observable<Models.Document[]> {
        const updateObservables: Observable<Models.Document>[] = []

        for (const lesson of lessons) {
            const updateObservable = from(
                this._appwrite.databases.updateDocument(
                    environment.main_database_id,
                    environment.masjid_lessons_collection_id,
                    lesson.lessonId,
                    {
                        name: lesson.name,
                        language: lesson.language,
                        duration: lesson.duration,
                        start: lesson.start,
                        end: lesson.end,
                        instructor: lesson.instructor,
                        masjid_id: lesson.masjid_id,
                        days: lesson.days,
                    }
                )
            ).pipe(
                tapResponse(
                    (document) => {
                        console.debug('Saved lesson document!', document)
                        return document
                    },
                    (error: any) => this.handleErrors(error)
                )
            )

            updateObservables.push(updateObservable)
        }

        return forkJoin(updateObservables).pipe(
            catchError((error) => {
                return this.handleErrors(error)
            })
        )
    }

    public getLessonsByMasjidId(
        masjidId: string
    ): Observable<MasjidLessonsInterface[]> {
        return from(
            this._appwrite.databases
                .listDocuments(
                    environment.main_database_id,
                    environment.masjid_lessons_collection_id,
                    [Query.equal('masjid_id', masjidId)]
                )
                .then((response) => {
                    return this.convertDocumentToLessonsInterface(
                        response.documents
                    )
                })
        ).pipe(
            tapResponse(
                (documents) => {
                    return documents
                },
                (error: AppwriteException | Error) => {
                    return this.handleErrors(error)
                }
            )
        )
    }

    /**
     * Delete lesson document by id
     */
    public deleteMasjidLesson(lessonId: string): Observable<LessonOperation> {
        return from(
            this._appwrite.databases.deleteDocument(
                environment.main_database_id,
                environment.masjid_lessons_collection_id,
                lessonId
            )
        ).pipe(
            map(() => {
                return { id: lessonId, operation: 'delete' } as LessonOperation
            }),
            catchError((error) => {
                return this.handleErrors(error)
            })
        )
    }

    public convertDocumentToLessonsInterface(
        documents: Models.Document[]
    ): MasjidLessonsInterface[] {
        if (documents.length > 0) {
            let lessons: MasjidLessonsInterface[] = []
            documents.forEach((document) => {
                lessons.push({
                    lessonId: document.$id,
                    days: Array.from(document['days'] || []),
                    duration: document['duration'] as string,
                    start: document['start'] as string,
                    name: document['name'] as string,
                    end: document['end'] as string,
                    instructor: document['instructor'] as string,
                    language: document['language'] as string,
                    masjid_id: document['masjid_id'] as string,
                })
            })
            return lessons
        } else return []
    }

    /**
     * handle new added lessons ou update the existing ones
     */
    public handleAllLessons(
        masjidData: MasjidDataInterface,
        newLessons: MasjidLessonsInterface[],
        updateLessons: MasjidLessonsInterface[],
        deleteLessons: MasjidLessonsInterface[]
    ): Observable<string[]> {
        let existingLessonIds: Set<string> = new Set()
        let newLessonIds: Set<string> = new Set()
        let deletedIds: Set<string> = new Set()

        const updateLessons$ = this.getUpdateLessonsObservable(updateLessons)

        const createLessons$ = this.getCreateLessonsObservable(newLessons)

        const deleteLessons$ = this.getDeleteLessonsObservable(deleteLessons)

        return forkJoin([
            ...updateLessons$,
            ...createLessons$,
            ...deleteLessons$,
        ]).pipe(
            switchMap((result) => {
                console.log(
                    'executed correctly handleAllLessons with forkJoin and the result is:',
                    result
                )
                const created = new Set(
                    result
                        .filter((item) => item.operation === 'create')
                        .map((item) => item.id)
                )
                const updated = new Set(
                    result
                        .filter((item) => item.operation === 'update')
                        .map((item) => item.id)
                )

                const deleted = new Set(
                    result
                        .filter((item) => item.operation === 'delete')
                        .map((item) => item.id)
                )

                const uniqueLessonIds = this.getUniqueLessonIds(
                    masjidData.lessonIds,
                    created,
                    updated,
                    deleted
                )

                console.log(
                    'uniqueLessonIds after process is:',
                    uniqueLessonIds
                )

                return of(uniqueLessonIds)
            }),
            catchError((error) => this.handleErrors(error))
        )
    }

    private getDeleteLessonsObservable(
        masjidLessons: MasjidLessonsInterface[]
    ): Observable<LessonOperation>[] {
        if (masjidLessons.length > 0) {
            let deleteLessons: Observable<LessonOperation>[] = []

            const deleteObservables = masjidLessons.map((lesson) =>
                deleteLessons.push(this.deleteMasjidLesson(lesson.lessonId!))
            )

            return deleteLessons
        } else return []
    }

    private getUpdateLessonsObservable(
        masjidLessons: MasjidLessonsInterface[]
    ): Observable<LessonOperation>[] {
        if (masjidLessons.length) {
            let lessons: Observable<LessonOperation>[] = []
            masjidLessons.forEach((element: MasjidLessonsInterface) => {
                lessons.push(
                    this.updateMasjidLesson(element).pipe(
                        map((res) => {
                            return {
                                id: res.$id,
                                operation: 'update',
                            } as LessonOperation
                        }),
                        catchError((error) => this.handleErrors(error))
                    )
                )
            })
            return lessons
        } else return []
    }

    private getCreateLessonsObservable(
        masjidLessons: MasjidLessonsInterface[]
    ): Observable<LessonOperation>[] {
        if (masjidLessons.length > 0) {
            let addLessons: Observable<LessonOperation>[] = []
            masjidLessons.forEach((item) => {
                addLessons.push(
                    this.createMasjidLesson(item).pipe(
                        map((result) => {
                            return {
                                id: result.$id,
                                operation: 'create',
                            } as LessonOperation
                        }),
                        catchError((error) => this.handleErrors(error))
                    )
                )
            })
            return addLessons
        } else return []
    }

    private getUniqueLessonIds(
        currentIds: string[],
        newIds: Set<string>,
        existingIds: Set<string>,
        deletedIds: Set<string>
    ): string[] {
        // Combine current, new, and existing IDs
        let combinedIds = [...currentIds, ...newIds, ...existingIds]

        // Remove deleted IDs
        const uniqueIds = combinedIds.filter((id) => !deletedIds.has(id))

        return uniqueIds
    }

    handleErrors(error: any): Observable<never> {
        let message: string
        if (error instanceof AppwriteException) {
            message = Utils.formatAppWriteException(error)
        } else message = Utils.formatException(error)
        this._logService.writeLogAsync({
            message: message,
            logType: LogType.Error,
        })
        return throwError(() => error)
    }
}
