<div class="admin-dashboard">
  <h1>Admin Dashboard</h1>

  <div class="dashboard-content">
    <div class="dashboard-section">
      <!-- Masjids List Column -->
      <div class="masjids-container">
        <h2>Masjids</h2>
        <div class="masjid-card" *ngFor="let masjid of masjids$ | async">
          <div class="masjid-info">
            <h3>{{ masjid.name }}</h3>
            <p>{{ masjid.description }}</p>
          </div>
          <div class="actions">
            <button mat-raised-button color="primary" (click)="editMasjid(masjid.id)">
              Edit Masjid
            </button>
            <button mat-raised-button color="accent" (click)="manageNews(masjid.id)">
              Manage News
            </button>
          </div>
        </div>
      </div>

      <!-- News Management Column -->
      <div class="news-container">
        <h2>News Management</h2>
        <p>News management features will be implemented here.</p>
      </div>
    </div>
  </div>
</div>
