import { SelectionModel } from '@angular/cdk/collections'
import { CommonModule } from '@angular/common'
import {
    Component,
    OnInit,
    Output,
    EventEmitter,
    ViewChild,
    Input,
} from '@angular/core'
import {
    FormGroup,
    FormGroupDirective,
    ReactiveFormsModule,
} from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatCardModule } from '@angular/material/card'
import {
    MatError,
    MatFormFieldModule,
    MatLabel,
} from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatSelectModule } from '@angular/material/select'
import { MatSort } from '@angular/material/sort'
import { MatTableDataSource, MatTableModule } from '@angular/material/table'
import { TranslocoModule, TranslocoService } from '@ngneat/transloco'
import {
    NgxMaterialTimepickerModule,
    NgxMaterialTimepickerTheme,
} from 'ngx-material-timepicker'
import { NgxTimepickerTimeControlComponent } from 'ngx-material-timepicker/src/app/material-timepicker/components/timepicker-field/timepicker-time-control/ngx-timepicker-time-control.component'
import { catchError } from 'rxjs'
import { Observable } from 'rxjs/internal/Observable'
import { of } from 'rxjs/internal/observable/of'
import {
    StepDirection,
    StepEvent,
    Steps,
} from 'src/app/masjids/interfaces/masjid.form.steps.interface'
import { MasjidLessonsInterface } from 'src/app/masjids/interfaces/masjidData.interface'
import Utils from 'src/app/shared/services/utils'
@Component({
    selector: 'app-masjid-lessons',
    templateUrl: './masjid-lessons.component.html',
    styleUrls: ['./masjid-lessons.component.scss'],
    standalone: true,
    imports: [
        MatCardModule,
        MatFormFieldModule,
        MatLabel,
        MatError,
        CommonModule,
        MatInputModule,
        NgxMaterialTimepickerModule,
        MatSelectModule,
        MatTableModule,
        MatIconModule,
        ReactiveFormsModule,
        TranslocoModule,
        MatButtonModule,
    ],
})
export class MasjidLessonsComponent implements OnInit {
    backendError$: Observable<string | null> = of(null)
    public masjidLessonsInfo: FormGroup
    @Output() changeStep: EventEmitter<StepEvent> =
        new EventEmitter<StepEvent>()
    @Output() lessons: EventEmitter<MasjidLessonsInterface[]> =
        new EventEmitter<MasjidLessonsInterface[]>()
    @Input() formGroupName: string
    @Input() isSubmittingSave$: Observable<boolean>
    lessonsDataSource: MasjidLessonsInterface[] = []
    @ViewChild('sort') sort: MatSort

    daysOfWeek: string[] = []
    selectedDays: string[] = []
    isEditing: boolean = false
    selection = new SelectionModel<MasjidLessonsInterface>(true, [])
    displayedColumns: string[] = [
        'name',
        'language',
        'duration',
        'start',
        'end',
        'instructor',
        'days',
        'actions',
    ]

    timepickerTheme: NgxMaterialTimepickerTheme = {
        container: {
            bodyBackgroundColor: '#fff',
            buttonColor: '#000',
        },
        dial: {
            dialBackgroundColor: '#fff',
            dialActiveColor: '#000',
        },
    }
    public StepDirection = StepDirection

    ngOnInit(): void {
        console.info(`log form name: ${this.formGroupName}`)
        this.masjidLessonsInfo = this.rootFormGroup.control.get(
            this.formGroupName
        ) as FormGroup
        console.info('log initFiles masjid lessons form')
        this.initDays()
    }

    initDays(): void {
        const currentLanguage = this._transLocoService.getActiveLang()
        this.getDaysBasedOnLanguage(currentLanguage).subscribe(
            (value) => {
                this.daysOfWeek = value
            },
            (error) => {
                console.error('Error fetching days:', error)
                // Provide a default set of days if fetching fails
                this.daysOfWeek = [
                    'Sunday',
                    'Monday',
                    'Tuesday',
                    'Wednesday',
                    'Thursday',
                    'Friday',
                    'Saturday',
                ]
            }
        )
    }

    doChangeStep(direction: StepDirection) {
        this.sendLessonsToParent(this.lessonsDataSource)
        this.changeStep.emit({
            currentStep: Steps.LessonsInfo,
            direction: direction,
        })
        this.lessonsDataSource = []
    }

    dataSource = new MatTableDataSource<MasjidLessonsInterface>(
        this.lessonsDataSource
    )

    ngAfterViewInit() {
        this.dataSource.sort = this.sort
    }

    clearTable() {
        this.dataSource.data = []
        this.lessonsDataSource = []
    }

    removeItem(row: MasjidLessonsInterface) {
        const index = this.dataSource.data.indexOf(row)
        if (index >= 0) {
            this.dataSource.data.splice(index, 1)
            this.dataSource.data = [...this.dataSource.data]
            this.dataSource._updateChangeSubscription()
        }
        const indexSourceData = this.lessonsDataSource.indexOf(row)
        if (indexSourceData >= 0) {
            this.lessonsDataSource.splice(indexSourceData, 1)
        }
    }

    addToTable() {
        if (this.masjidLessonsInfo.valid) {
            console.log(
                'this.masjidLessonsInfo.value is ',
                this.masjidLessonsInfo.value
            )
            const selectedDays = this.masjidLessonsInfo.value.lessonDays
            const newLesson = {
                lessonId:
                    this.masjidLessonsInfo.value.lessonId ||
                    Utils.generateUniqueId(),
                name: this.masjidLessonsInfo.value.lessonName,
                start: this.masjidLessonsInfo.value.lessonStart,
                end: this.masjidLessonsInfo.value.lessonEnd,
                language: this.masjidLessonsInfo.value.lessonLanguage,
                instructor: this.masjidLessonsInfo.value.lessonInstructor,
                duration: this.masjidLessonsInfo.value.lessonDuration,

                days: Array.isArray(selectedDays)
                    ? selectedDays.map((day: string) => day.trim())
                    : [selectedDays],
            } as MasjidLessonsInterface
            console.log('new lesson:', newLesson)
            const existingLessonIndex = this.lessonsDataSource.findIndex(
                (lesson) => lesson.lessonId === newLesson.lessonId
            )
            console.log('existingLessonIndex is ', existingLessonIndex)

            if (existingLessonIndex !== -1)
                this.lessonsDataSource[existingLessonIndex] = newLesson
            else this.lessonsDataSource.push(newLesson)

            this.dataSource.data = [...this.lessonsDataSource]
            this.masjidLessonsInfo.reset()
        } else {
            console.log(
                'this.masjidLessonsInfo is ',
                this.masjidLessonsInfo.valid
            )
        }
    }

    editItem(row: MasjidLessonsInterface) {
        this.masjidLessonsInfo.patchValue({
            lessonName: row.name,
            lessonLanguage: row.language,
            lessonDuration: row.duration,
            lessonStart: row.start,
            lessonEnd: row.end,
            lessonInstructor: row.instructor,
            lessonDays: row.days,
            masjid_id: row.masjid_id,
            lessonId: row.lessonId,
        })
        this.updateDuration(row.start, 'start')
        this.updateDuration(row.end, 'end')
    }

    public getDaysBasedOnLanguage(language: string): Observable<string[]> {
        return this._transLocoService
            .selectTranslate(
                'masjidData.days',
                {
                    array: true,
                },
                language
            )
            .pipe(
                catchError((error) => {
                    console.error('Error fetching days:', error)
                    // Provide a default set of days if fetching fails
                    return of([
                        'Sunday',
                        'Monday',
                        'Tuesday',
                        'Wednesday',
                        'Thursday',
                        'Friday',
                        'Saturday',
                    ])
                })
            )
    }

    sendLessonsToParent(masjidData: MasjidLessonsInterface[]) {
        this.lessons.emit(masjidData)
    }

    updateDuration(timeUpdated: string, action: string) {
        if (action == 'end')
            this.masjidLessonsInfo.patchValue(
                { lessonEnd: timeUpdated },
                { emitEvent: false }
            )
        if (action == 'start')
            this.masjidLessonsInfo.patchValue(
                { lessonStart: timeUpdated },
                { emitEvent: false }
            )

        console.log(`time picked for ${action} time:`, timeUpdated)
        const start = this.masjidLessonsInfo.get('lessonStart')!.value
        const end = this.masjidLessonsInfo.get('lessonEnd')!.value

        if (start && end) {
            const startTime = new Date(`2000-01-01T${start}`)
            const endTime = new Date(`2000-01-01T${end}`)
            const duration = new Date(endTime.getTime() - startTime.getTime())

            // Format the duration as HH:mm
            const hours = duration.getUTCHours().toString().padStart(2, '0')
            const minutes = duration.getUTCMinutes().toString().padStart(2, '0')
            const formattedDuration = `${hours}:${minutes}`

            this.masjidLessonsInfo.patchValue(
                { lessonDuration: formattedDuration },
                { emitEvent: false }
            )
        }
    }

    constructor(
        private rootFormGroup: FormGroupDirective,
        private _transLocoService: TranslocoService
    ) {}
}
