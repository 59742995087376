import { Routes } from "@angular/router";
import { AuthenticatedGuard } from "../shared/guards/authenticated.guard";
import { AdminDashboardComponent } from "./components/admin-dashboard/admin-dashboard.component";
import { CreateMasjidComponent } from "./components/create-masjid/create-masjid.component";
import { DetailComponent } from "./components/details/detail/detail.component";
import { MasjidListComponent } from "./components/details/masjid-list/masjid-list.component";
import { EditMasjidComponent } from "./components/edit-masjid/edit-masjid.component";

export const masjidRoutes: Routes = [
    { path: '', redirectTo: '/home', pathMatch: 'full' },
    {
        path: '',
        children: [
            { path: 'dashboard', component: AdminDashboardComponent, canActivate: [AuthenticatedGuard] },
            { path: 'create', component: CreateMasjidComponent, canActivate: [AuthenticatedGuard] },
            { path: 'details/:masjidId', component: DetailComponent },
            { path: 'list', component: MasjidListComponent },
            { path: 'edit/:masjidId', component: EditMasjidComponent, canActivate: [AuthenticatedGuard] },
        ],
    },
];