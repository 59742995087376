import { CommonModule } from '@angular/common'
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import {
    FormGroup,
    FormGroupDirective,
    ReactiveFormsModule,
} from '@angular/forms'
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { MatButtonModule } from '@angular/material/button'
import { MatCardModule } from '@angular/material/card'
import { MatLabel, MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { TranslocoModule } from '@ngneat/transloco'
import { Observable, map, of, startWith } from 'rxjs'
import {
    StepDirection,
    StepEvent,
    Steps,
} from 'src/app/masjids/interfaces/masjid.form.steps.interface'
import { Country } from 'src/app/shared/interfaces/country.interface'
import utils from 'src/app/shared/services/utils'

@Component({
    selector: 'app-masjid-address',
    templateUrl: './masjid-address.component.html',
    styleUrls: ['./masjid-address.component.scss'],
    standalone: true,
    imports: [
        MatCardModule,
        MatLabel,
        MatFormFieldModule,
        ReactiveFormsModule,
        CommonModule,
        MatAutocompleteModule,
        TranslocoModule,
        MatButtonModule,
        MatInputModule
    ],
})
export class MasjidAddressComponent implements OnInit {
    backendError$: Observable<string | null> = of(null)
    public masjidAddressInfo: FormGroup
    public StepDirection = StepDirection

    @Output() changeStep: EventEmitter<StepEvent> =
        new EventEmitter<StepEvent>()
    @Input() formGroupName: string
    filteredCountries?: Observable<Country[]>
    @Input() countries: Country[]

    ngOnInit(): void {
        this.masjidAddressInfo = this.rootFormGroup.control.get(
            this.formGroupName
        ) as FormGroup

        this.filteredCountries = this.masjidAddressInfo.controls[
            'country'
        ].valueChanges.pipe(
            startWith(''),
            map((value) => this._filter(value || ''))
        )
    }
    doChangeStep(direction: StepDirection) {
        if (!this.masjidAddressInfo.valid && direction === 'next') {
            utils.validateAllFormFields(this.masjidAddressInfo)
            return
        }

        this.changeStep.emit({
            currentStep: Steps.AddressInfo,
            direction: direction,
        })
    }
    private _filter(value: string): Country[] {
        console.log(value)
        if (value === null) {
            value = ''
        }
        const filterValue = value.toLowerCase()

        return this.countries.filter((country) =>
            country.name.toLowerCase().startsWith(filterValue)
        )
    }
    constructor(private rootFormGroup: FormGroupDirective) {}
}
