import { Component } from '@angular/core'
import { Store } from '@ngrx/store'
import { Observable, of } from 'rxjs'
import { MasjidDataInterface } from 'src/app/masjids/interfaces/masjidData.interface'
import { loadAdminMasjids } from 'src/app/masjids/store/actions/masjid.actions'
import { AppState } from 'src/app/store/app.state'
import { selectAdminMasjids } from 'src/app/store/selectors'
import { setMasjidData } from '../../store/actions/edit.masjid.actions'
import { Router } from '@angular/router'
import { MatCardModule } from '@angular/material/card'
import { MatListModule } from '@angular/material/list'
import { MatToolbarModule } from '@angular/material/toolbar'
import { CommonModule } from '@angular/common'
import { NotificationService } from 'src/app/shared/services/notification.service'
import { userAdminMasjidListSelector } from '../../store/selectors'
import { MatGridListModule } from '@angular/material/grid-list'
import { MatButtonModule } from '@angular/material/button'

@Component({
    selector: 'app-admin-dashboard',
    templateUrl: './admin-dashboard.component.html',
    styleUrl: './admin-dashboard.component.scss',
    standalone: true,
    imports: [
        MatCardModule,
        MatListModule,
        MatToolbarModule,
        CommonModule,
        MatGridListModule,
        MatButtonModule
    ],
})
export class AdminDashboardComponent {
    masjids$: Observable<MasjidDataInterface[]> = of([])
    constructor(
        private store: Store<AppState>,
        private _router: Router,
        private _notification: NotificationService
    ) {
        this.masjids$ = this.store.select(userAdminMasjidListSelector)
    }

    ngOnInit(): void {
        this._notification.loading('Loading admin masjids...')
        this.store.dispatch(loadAdminMasjids())
    }
    editMasjid(masjidId: string): void {
        // Dispatch action to set the masjid data for editing
        this.store.dispatch(
            setMasjidData({
                data: { id: masjidId }, // Add any other data you need to prepopulate
            })
        )
        // Navigate to the edit masjid page
        this._router.navigate(['/masjid/edit', masjidId])
    }
    manageNews(masjidId: string): void {
        this._router.navigate(['/masjid/news', masjidId])
    }
}
