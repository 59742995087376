export enum Steps {
    BasicInfo = 'basicInfo',
    AddressInfo = 'addressInfo',
    ContactInfo = 'contactInfo',
    LeaderInfo = 'leaderInfo',
    NGOInfo = 'NGOInfo',
    LessonsInfo = 'lessonsInfo',
}
export enum StepDirection {
    Next = 'next',
    Back = 'back',
    Finish = 'finish',
}
export interface StepEvent {
    currentStep: Steps
    direction: StepDirection
}

type ValidStepDirection = Exclude<StepDirection, StepDirection.Finish>;

export const stepTransitions: Record<Steps, { next?: Steps; back?: Steps }> = {
    [Steps.BasicInfo]: { next: Steps.AddressInfo },
    [Steps.AddressInfo]: { back: Steps.BasicInfo, next: Steps.ContactInfo },
    [Steps.ContactInfo]: { back: Steps.AddressInfo, next: Steps.LeaderInfo },
    [Steps.LeaderInfo]: { back: Steps.ContactInfo, next: Steps.NGOInfo },
    [Steps.NGOInfo]: { back: Steps.LeaderInfo, next: Steps.LessonsInfo },
    [Steps.LessonsInfo]: { back: Steps.NGOInfo },
};

