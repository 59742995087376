import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { ComponentStore } from '@ngrx/component-store'
import { Actions, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { AppwriteException } from 'appwrite'
import {
    Observable,
    catchError,
    map,
    of,
    switchMap,
    tap,
    throwError,
} from 'rxjs'
import { LogType } from 'src/app/shared/interfaces/log.interface'
import { LessonsService } from 'src/app/shared/services/lessons.service'
import { LogService } from 'src/app/shared/services/log.service'
import { MasjidDocumentService } from 'src/app/shared/services/masjid.service'
import { NotificationService } from 'src/app/shared/services/notification.service'
import Utils from 'src/app/shared/services/utils'
import {
    MasjidStateInterface,
    initialMasjidState,
} from '../../interfaces/masjid.state.interface'
import { MasjidDataInterface } from '../../interfaces/masjidData.interface'
import {
    loadMasjidList,
    loadMasjidListFailure,
    loadMasjidListSuccess,
    viewMasjidDetailsAndNavigate,
} from '../actions/masjid.actions'

@Injectable({ providedIn: 'root' })
export class MasjidStore extends ComponentStore<MasjidStateInterface> {
    constructor(
        private actions$: Actions,
        private _store: Store,
        private _masjidService: MasjidDocumentService,
        private _logService: LogService,
        private _lessonsService: LessonsService,
        private _notificationService: NotificationService,
        private _router: Router
    ) {
        super(initialMasjidState)
    }

    public readonly setMasjidList = this.updater(
        (state, data: MasjidDataInterface[]) => ({
            ...state,
            masjidsList: data,
        })
    )

    public readonly setMasjid = this.updater(
        (state, data: MasjidDataInterface) => ({
            ...state,
            masjid: data,
        })
    )

    public readonly resetState = this.updater((state) => initialMasjidState)

    public readonly getMasjidList = this.select((state) => state.masjidsList)

    public readonly getMasjid = this.select((state) => state.masjid)

    viewMasjidDetailsAndNavigate$ = this.effect(() =>
        this.actions$.pipe(
            ofType(viewMasjidDetailsAndNavigate),
            tap((action) => {
                console.log('starting effect viewMasjidDetailsAndNavigate$')
                const masjidId = action.masjidId
                this._router.navigate(['masjid', 'details', masjidId])
            })
        )
    )

    loadMasjidList$ = this.effect(() =>
        this.actions$.pipe(
            ofType(loadMasjidList),
            tap(() => {
                console.log('starting effect loadMasjidList$')
                this._notificationService.loading()
            }),
            switchMap(() =>
                this._masjidService.getMasjidList().pipe(
                    map((masjids) => {
                        console.log('end effect of loadMasjidList$')
                        return this._store.dispatch(
                            loadMasjidListSuccess({ masjids })
                        )
                    })
                )
            ),
            catchError((error) => {
                this.handleErrors(error)
                return of(loadMasjidListFailure({ error: error }))
            })
        )
    )

    loadMasjidListSuccess$ = this.effect(() =>
        this.actions$.pipe(
            ofType(loadMasjidListSuccess),
            tap((action) => {
                console.log('starting effect loadMasjidListSuccess$')
                this._notificationService.removeLoading()
                console.log('End of effect loadMasjidListSuccess$', action)
                this.setMasjidList(action.masjids)
                return of(action.masjids)
            })
        )
    )

    loadMasjidListFailure$ = this.effect(() =>
        this.actions$.pipe(
            ofType(loadMasjidListFailure),
            tap((action) => {
                console.log('starting effect loadMasjidListFailure$')
                this._notificationService.removeLoading()
                console.log('End of effect loadMasjidListFailure$', action)
            })
        )
    )

    handleErrors(error: Error | AppwriteException): Observable<never> {
        let message: string
        if (error instanceof AppwriteException) {
            message = Utils.formatAppWriteException(error)
        } else message = Utils.formatException(error)
        this._logService.writeLogAsync({
            message: message,
            logType: LogType.Error,
        })
        return throwError(() => error)
    }
}
