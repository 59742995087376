import { CommonModule } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import { Store } from '@ngrx/store'
import { MasjidStateInterface } from 'src/app/masjids/interfaces/masjid.state.interface'
import { loadMasjidList } from 'src/app/masjids/store/actions/masjid.actions'
import { MasjidStore } from 'src/app/masjids/store/states/masjid.state'
import { MasjidCardComponent } from './masjid-card/masjid-card.component'

@Component({
    selector: 'app-masjid-list',
    templateUrl: './masjid-list.component.html',
    styleUrls: ['./masjid-list.component.scss'],
    standalone: true,
    imports: [CommonModule, MasjidCardComponent]
})
export class MasjidListComponent implements OnInit {
    constructor(
        private _store: Store<MasjidStateInterface>,
        private _componentStore: MasjidStore
    ) {}
    ngOnInit(): void {
        this._componentStore.resetState()
        this._store.dispatch(loadMasjidList())
    }
    get getMasjidList() {
        return this._componentStore.getMasjidList
    }
}
