<div class="flex-center">
  <mat-card class="masjid-card" (click)="onViewDetails()">
    <mat-card-header>
      <mat-card-title
        ><h2>{{ masjid.name }}</h2></mat-card-title
      >
    </mat-card-header>
    <mat-card-content>
      <div class="row container-image">
        <img
          *ngIf="masjid.ImagesIds && masjid.ImagesIds.length > 0"
          [src]="getMasjidImageSrc(masjid.ImagesIds[0])"
          alt="{{ masjid.name }}"
          class="masjid-image"
        />
      </div>
      <div class="row">
        <p>
          {{ masjid.street }}, {{ masjid.city }}, {{ masjid.state }},
          {{ masjid.country }}, {{ masjid.postalCode }}
        </p>
      </div>
    </mat-card-content>
  </mat-card>
</div>
