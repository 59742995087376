<ng-container *ngIf="getMasjidList | async; else loading">
  <app-masjid-card
    [masjid]="masjid"
    *ngFor="let masjid of getMasjidList | async"
  ></app-masjid-card>
</ng-container>

<ng-template #loading>
  <p>Loading masjids...</p>
</ng-template>
