import { CommonModule } from '@angular/common'
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core'
import { FormGroup, FormGroupDirective, ReactiveFormsModule } from '@angular/forms'
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { MatButtonModule } from '@angular/material/button'
import { MatCardModule } from '@angular/material/card'
import { MatLabel, MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatSelectModule } from '@angular/material/select'
import { TranslocoModule } from '@ngneat/transloco'
import { Observable, of } from 'rxjs'
import {
    StepDirection,
    StepEvent,
    Steps,
} from 'src/app/masjids/interfaces/masjid.form.steps.interface'
import { PhotoUploadComponent } from 'src/app/shared/components/file-upload/photo-upload/photo-upload.component'
import {
    PhotoInterface,
    PhotoType,
} from 'src/app/shared/interfaces/Image.interface'
import Utils from 'src/app/shared/services/utils'

@Component({
    selector: 'app-basic-information',
    templateUrl: './basic-information.component.html',
    styleUrls: ['./basic-information.component.scss'],
    standalone: true,
    imports: [
        MatCardModule,
        MatLabel,
        MatFormFieldModule,
        ReactiveFormsModule,
        CommonModule,
        MatAutocompleteModule,
        MatSelectModule,
        PhotoUploadComponent,
        TranslocoModule,
        MatButtonModule,
        MatInputModule
    ],
})
export class BasicInformationComponent implements OnInit {
    backendError$: Observable<string | null> = of(null)
    public masjidBasicInfo: FormGroup
    public openHour: Array<number> = Utils.arrayRange(1, 25)
    public closeHour: Array<number> = Utils.arrayRange(1, 25)
    selectedOpenHour: number
    selectedCloseHour: number
    public StepDirection = StepDirection
    public photoType: PhotoType = PhotoType.Masjid

    @Output() changeStep: EventEmitter<StepEvent> =
        new EventEmitter<StepEvent>()
    @Output() masjidImages: EventEmitter<PhotoInterface[]> = new EventEmitter<
        PhotoInterface[]
    >()
    @Input() isUserLoggedIn: boolean
    @Input() formGroupName: string
    @Input() bucketId: string
    @Input() initFiles: PhotoInterface[]

    ngOnInit(): void {
        console.log(this.formGroupName)
        this.masjidBasicInfo = this.rootFormGroup.control.get(
            this.formGroupName
        ) as FormGroup
        console.log('log initFiles masjid photos', this.initFiles)
        if (this.initFiles && this.initFiles.length > 0) {
            this.initFiles = this.initFiles.filter(
                (file, index, self) =>
                    file.photoType === PhotoType.Masjid &&
                    self.findIndex((f) => f.id === file.id) === index
            ); // Ensure unique files
        }
    }
    doChangeStep(direction: StepDirection) {
        console.log(`doChangeStep: Direction: ${direction}`)
        if (!this.masjidBasicInfo.valid) {
            Utils.validateAllFormFields(this.masjidBasicInfo)
            console.log(this.masjidBasicInfo.value)
            return
        }

        if (direction === 'next') {
            this.changeStep.emit({
                currentStep: Steps.BasicInfo,
                direction: direction,
            })
        }
    }

    sendFilesToParentForm(files: PhotoInterface[]) {
        console.log('basic info form sending data to parent form', files)
        this.masjidImages.emit(files)
    }

    constructor(private rootFormGroup: FormGroupDirective) {}
}
