<ng-container [formGroup]="masjidOrganizationInfo">
  <mat-card
    class="organization-info-card"
    *transloco="let t; read: 'masjidData'"
  >
    <mat-card-header class="flex-center">
      <mat-card-title>
        <h1 class="text-2xl sm:text-3xl">{{ t('NgoInformation') }}</h1>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="row" style="margin-top: 1.5rem">
        <div class="col">
          <mat-form-field
            appearance="outline"
            floatLabel="always"
            class="full-width"
          >
            <mat-label>{{ t('NgoNameLabel') }}</mat-label>
            <input
              matInput
              type="text"
              placeholder="{{ t('NgoNameLabel') }}"
              formControlName="NgoName"
            />
            <mat-error
              *ngIf="
                masjidOrganizationInfo.controls['NgoName'].hasError('pattern')
              "
            >
              {{ t('NgoNameLabel') + ' ' + t('errorIsInvalid') }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-form-field
            appearance="outline"
            floatLabel="always"
            class="full-width"
          >
            <mat-label>{{ t('NgoTelephoneLabel') }}</mat-label>
            <input
              matInput
              type="text"
              placeholder="{{ t('NgoTelephoneLabel') }}"
              formControlName="NgoTelephone"
            />
            <mat-error
              *ngIf="
                masjidOrganizationInfo.controls['NgoTelephone'].hasError(
                  'pattern'
                )
              "
            >
              {{ t('NgoTelephoneLabel') + ' ' + t('errorIsInvalid') }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field
            appearance="outline"
            floatLabel="always"
            class="full-width"
          >
            <mat-label>{{ t('NgoMobileLabel') }}</mat-label>
            <input
              matInput
              type="text"
              placeholder="{{ t('NgoMobileLabel') }}"
              formControlName="NgoMobile"
            />
            <mat-error
              *ngIf="
                masjidOrganizationInfo.controls['NgoMobile'].hasError('pattern')
              "
            >
              {{ t('NgoMobileLabel') + ' ' + t('errorIsInvalid') }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-form-field
            appearance="outline"
            floatLabel="always"
            class="full-width"
          >
            <mat-label>{{ t('NgoEmailLabel') }}</mat-label>
            <input
              matInput
              type="email"
              placeholder="{{ t('NgoEmailLabel') }}"
              formControlName="NgoEmail"
            />
            <mat-error
              *ngIf="
                masjidOrganizationInfo.controls['NgoEmail'].hasError('pattern')
              "
            >
              {{ t('NgoEmailLabel') + ' ' + t('errorIsInvalid') }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </mat-card-content>
    <mat-card-actions
      class="flex-center full-width"
      style="padding-bottom: 1.5rem"
    >
      <div class="row">
        <div class="col flex-center">
          <button
            type="button"
            mat-raised-button
            color="warn"
            (click)="doChangeStep(StepDirection.Back)"
          >
            {{ t('back') }}
          </button>
        </div>
        <div class="col flex-center">
          <button
            type="button"
            mat-raised-button
            color="accent"
            (click)="doChangeStep(StepDirection.Next)"
          >
            {{ t('next') }}
          </button>
        </div>
      </div>
    </mat-card-actions>
  </mat-card>
</ng-container>
