import { CommonModule } from '@angular/common'
import { Component, Input } from '@angular/core'
import { MatCardModule } from '@angular/material/card'
import { Store } from '@ngrx/store'
import { MasjidDataInterface } from 'src/app/masjids/interfaces/masjidData.interface'
import { viewMasjidDetailsAndNavigate } from 'src/app/masjids/store/actions/masjid.actions'
import { FileStorageService } from 'src/app/shared/services/file-storage.service'
import { environment } from 'src/environments/environment'

@Component({
    selector: 'app-masjid-card',
    templateUrl: './masjid-card.component.html',
    styleUrls: ['./masjid-card.component.scss'],
    standalone: true,
    imports: [MatCardModule, CommonModule],
})
export class MasjidCardComponent {
    constructor(
        private _fileService: FileStorageService,
        private _store: Store
    ) {}

    @Input() masjid: MasjidDataInterface

    getMasjidImageSrc(imageId: string): string {
        return this._fileService.getFilePreviewUrl(
            environment.masjid_images_bucket,
            this.masjid.ImagesIds[0]
        )
    }

    onViewDetails() {
        // Dispatch an action to view masjid details
        this._store.dispatch(
            viewMasjidDetailsAndNavigate({ masjidId: this.masjid.id })
        )
    }
}
