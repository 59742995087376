<ng-container [formGroup]="masjidContactInfo">
  <mat-card class="contact-info-card" *transloco="let t; read: 'masjidData'">
    <mat-card-header class="flex-center">
      <mat-card-title>
        <h1 class="text-2xl sm:text-3xl">{{ t('contactInformation') }}</h1>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div style="color: red; padding-left: 30px; padding-bottom: 20px">
        {{ backendError$ | async }}
      </div>
      <div class="row" style="margin-top: 1.5rem">
        <div class="col">
          <mat-form-field
            appearance="outline"
            floatLabel="always"
            class="full-width"
          >
            <mat-label>{{ t('telephoneLabel') }}</mat-label>
            <input
              matInput
              type="tel"
              placeholder="{{ t('telephoneLabel') }}"
              formControlName="telephone"
            />
            <mat-error
              *ngIf="
                masjidContactInfo.controls['telephone'].hasError('pattern')
              "
            >
              {{ t('telephoneLabel') + ' ' + t('errorIsInvalid') }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field
            appearance="outline"
            floatLabel="always"
            class="full-width"
          >
            <mat-label>{{ t('mobileLabel') }}</mat-label>
            <input
              matInput
              type="text"
              placeholder="{{ t('mobileLabel') }}"
              formControlName="mobile"
            />
            <mat-error
              *ngIf="masjidContactInfo.controls['mobile'].hasError('pattern')"
            >
              {{ t('mobileLabel') + ' ' + t('errorIsInvalid') }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-form-field
            appearance="outline"
            floatLabel="always"
            class="full-width"
          >
            <mat-label>{{ t('whatsappLabel') }}</mat-label>
            <input
              matInput
              type="text"
              placeholder="{{ t('whatsappLabel') }}"
              formControlName="whatsapp"
            />
            <mat-error
              *ngIf="masjidContactInfo.controls['whatsapp'].hasError('pattern')"
            >
              {{ t('whatsappLabel') + ' ' + t('errorIsInvalid') }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <mat-card-title>{{ t('socialMedia') }}</mat-card-title>
        </div>
      </div>
      <div class="row" style="margin-top: 1.5rem">
        <div class="col">
          <mat-form-field
            appearance="outline"
            floatLabel="always"
            class="full-width"
          >
            <mat-label>{{ t('facebookLabel') }}</mat-label>
            <input
              matInput
              type="text"
              placeholder="{{ t('facebookLabel') }}"
              formControlName="facebookUrl"
            />
            <mat-error
              *ngIf="
                masjidContactInfo.controls['facebookUrl'].hasError('pattern')
              "
            >
              {{ t('facebookLabel') + ' ' + t('errorIsInvalid') }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-form-field
            appearance="outline"
            floatLabel="always"
            class="full-width"
          >
            <mat-label>{{ t('instagramLabel') }}</mat-label>
            <input
              matInput
              type="text"
              placeholder="{{ t('instagramLabel') }}"
              formControlName="instagramUrl"
            />
            <mat-error
              *ngIf="
                masjidContactInfo.controls['instagramUrl'].hasError('pattern')
              "
            >
              {{ t('instagramLabel') + ' ' + t('errorIsInvalid') }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </mat-card-content>
    <mat-card-actions
      class="flex-center full-width"
      style="padding-bottom: 1.5rem"
    >
      <div class="row">
        <div class="col flex-center">
          <button
            type="button"
            mat-raised-button
            color="warn"
            (click)="doChangeStep(StepDirection.Back)"
          >
            {{ t('back') }}
          </button>
        </div>
        <div class="col flex-center">
          <button
            type="button"
            mat-raised-button
            color="accent"
            (click)="doChangeStep(StepDirection.Next)"
          >
            {{ t('next') }}
          </button>
        </div>
      </div>
    </mat-card-actions>
  </mat-card>
</ng-container>
