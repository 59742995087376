import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { CreateMasjidStateInterface, initialCreateMasjidState } from 'src/app/masjids/interfaces/masjid.state.interface';
import { MasjidDataInterface } from 'src/app/masjids/interfaces/masjidData.interface';
import { PhotoInterface } from 'src/app/shared/interfaces/Image.interface';

@Injectable({ providedIn: 'root' })
export class CreateMasjidStore extends ComponentStore<CreateMasjidStateInterface> {
  readonly isSubmittingSave$ = this.select((state) => state.isSubmittingSave);
  readonly backendError$ = this.select((state) => state.backendError);

  constructor() {
    super(initialCreateMasjidState);
  }

  readonly setIsSubmitting = this.updater((state, isSubmitting: boolean) => ({
    ...state,
    isSubmittingSave: isSubmitting,
  }));

  readonly setBackendError = this.updater((state, error: string | null) => ({
    ...state,
    backendError: error,
    isSubmittingSave: false,
  }));

  readonly updateMasjidData = this.updater((state, masjid: MasjidDataInterface) => ({
    ...state,
    masjidData: { ...state.masjidData, ...masjid },
  }));

  readonly updateMasjidPhotos = this.updater((state, masjidPhotos: PhotoInterface[]) => ({
    ...state,
    masjidPhotos: [...state.masjidPhotos, ...masjidPhotos.filter(photo => !state.masjidPhotos.find(p => p.id === photo.id))],
  }));
}
