<ng-container [formGroup]="masjidAddressInfo">
  <mat-card class="address-info-card" *transloco="let t; read: 'masjidData'">
    <mat-card-header class="flex-center">
      <mat-card-title>
        <h1 class="text-2xl sm:text-3xl">{{ t('addressInformation') }}</h1>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div style="color: red; padding-left: 30px; padding-bottom: 20px">
        {{ backendError$ | async }}
      </div>
      <div class="row" style="margin-top: 1.5rem">
        <div class="col" style="margin-inline: 0">
          <div class="row">
            <div class="col">
              <mat-form-field
                appearance="outline"
                floatLabel="always"
                class="full-width"
              >
                <mat-label>{{ t('streetLabel') }}</mat-label>
                <input
                  matInput
                  type="text"
                  placeholder="{{ t('streetLabel') }}"
                  formControlName="street"
                />
                <mat-error
                  *ngIf="
                    masjidAddressInfo.controls['street'].hasError('pattern')
                  "
                >
                  {{ t('streetLabel') + ' ' + t('errorIsInvalid') }}
                </mat-error>
                <mat-error
                  *ngIf="
                    masjidAddressInfo.controls['street'].hasError('required')
                  "
                >
                  {{ t('streetLabel') + ' ' + t('errorIsRequired') }}
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col">
              <mat-form-field
                appearance="outline"
                floatLabel="always"
                class="full-width"
              >
                <mat-label>{{ t('houseNumberLabel') }}</mat-label>
                <input
                  matInput
                  type="text"
                  placeholder="{{ t('houseNumberLabel') }}"
                  formControlName="houseNumber"
                />
                <mat-error
                  *ngIf="
                    masjidAddressInfo.controls['houseNumber'].hasError(
                      'pattern'
                    )
                  "
                >
                  {{ t('houseNumberLabel') + ' ' + t('errorIsInvalid') }}
                </mat-error>
                <mat-error
                  *ngIf="
                    masjidAddressInfo.controls['houseNumber'].hasError(
                      'required'
                    )
                  "
                >
                  {{ t('houseNumberLabel') + ' ' + t('errorIsRequired') }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <mat-form-field
                appearance="outline"
                floatLabel="always"
                class="full-width"
              >
                <mat-label>{{ t('cityLabel') }}</mat-label>
                <input
                  matInput
                  type="text"
                  placeholder="{{ t('cityLabel') }}"
                  formControlName="city"
                />
                <mat-error
                  *ngIf="masjidAddressInfo.controls['city'].hasError('pattern')"
                >
                  {{ t('cityLabel') + ' ' + t('errorIsInvalid') }}
                </mat-error>
                <mat-error
                  *ngIf="
                    masjidAddressInfo.controls['city'].hasError('required')
                  "
                >
                  {{ t('cityLabel') + ' ' + t('errorIsRequired') }}
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col">
              <mat-form-field
                appearance="outline"
                floatLabel="always"
                class="full-width"
              >
                <mat-label>{{ t('stateLabel') }}</mat-label>
                <input
                  matInput
                  type="text"
                  placeholder="{{ t('stateLabel') }}"
                  formControlName="state"
                />
                <mat-error
                  *ngIf="
                    masjidAddressInfo.controls['state'].hasError('pattern')
                  "
                >
                  {{ t('stateLabel') + ' ' + t('errorIsInvalid') }}
                </mat-error>
                <mat-error
                  *ngIf="
                    masjidAddressInfo.controls['state'].hasError('required')
                  "
                >
                  {{ t('stateLabel') + ' ' + t('errorIsRequired') }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <mat-form-field
                appearance="outline"
                floatLabel="always"
                class="full-width"
              >
                <mat-label>{{ t('countryLabel') }}</mat-label>
                <input
                  type="text"
                  placeholder="{{ t('countryLabel') }}"
                  matInput
                  formControlName="country"
                  [matAutocomplete]="auto"
                />
                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                  <mat-option
                    *ngFor="let country of filteredCountries | async"
                    [value]="country.name"
                  >
                    {{ country.name }}
                  </mat-option>
                </mat-autocomplete>
                <mat-error
                  *ngIf="
                    masjidAddressInfo.controls['country'].hasError(
                      'countryValidator'
                    )
                  "
                >
                  {{ t('countryLabel') + ' ' + t('errorIsInvalid') }}
                </mat-error>
                <mat-error
                  *ngIf="
                    masjidAddressInfo.controls['country'].hasError('required')
                  "
                >
                  {{ t('countryLabel') + ' ' + t('errorIsRequired') }}
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col">
              <mat-form-field
                appearance="outline"
                floatLabel="always"
                class="full-width"
              >
                <mat-label>{{ t('postalCodeLabel') }}</mat-label>
                <input
                  matInput
                  #postalCode
                  placeholder="{{ t('postalCodeLabel') }}"
                  type="text"
                  formControlName="postalCode"
                />
                <mat-error
                  *ngIf="
                    masjidAddressInfo.controls['postalCode'].hasError('pattern')
                  "
                >
                  {{ t('postalCodeLabel') + ' ' + t('errorIsInvalid') }}
                </mat-error>
                <mat-error
                  *ngIf="
                    masjidAddressInfo.controls['postalCode'].hasError(
                      'required'
                    )
                  "
                >
                  {{ t('postalCodeLabel') + ' ' + t('errorIsRequired') }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </mat-card-content>
    <mat-card-actions
      class="flex-center full-width"
      style="padding-bottom: 1.5rem"
    >
      <div class="row">
        <div class="col flex-center">
          <button
            type="button"
            mat-raised-button
            color="warn"
            (click)="doChangeStep(StepDirection.Back)"
          >
            {{ t('back') }}
          </button>
        </div>
        <div class="col flex-center">
          <button
            type="button"
            mat-raised-button
            color="accent"
            (click)="doChangeStep(StepDirection.Next)"
          >
            {{ t('next') }}
          </button>
        </div>
      </div>
    </mat-card-actions>
  </mat-card>
</ng-container>
