<ng-container [formGroup]="masjidLessonsInfo">
  <mat-card class="lessons-info-card" *transloco="let t; read: 'masjidData'">
    <mat-card-header class="flex-center">
      <mat-card-title>
        <h1 class="text-2xl sm:text-3xl">{{ t('lessonInformation') }}</h1>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div style="color: red; padding-left: 30px; padding-bottom: 20px">
        {{ backendError$ | async }}
      </div>

      <div class="row" style="margin-top: 1.5rem">
        <div class="col">
          <mat-form-field
            appearance="outline"
            floatLabel="always"
            class="full-width"
          >
            <mat-label>{{ t('lessonNameLabel') }}</mat-label>
            <input
              matInput
              type="text"
              placeholder="{{ t('lessonNameLabel') }}"
              formControlName="lessonName"
            />
            <mat-error
              *ngIf="
                masjidLessonsInfo.controls['lessonName'].hasError('pattern')
              "
            >
              {{ t('lessonNameLabel') + ' ' + t('errorIsInvalid') }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <mat-form-field
            appearance="outline"
            floatLabel="always"
            class="full-width"
          >
            <mat-label>{{ t('lessonLanguageLabel') }}</mat-label>
            <input
              matInput
              type="text"
              placeholder="{{ t('lessonLanguageLabel') }}"
              formControlName="lessonLanguage"
            />

            <mat-error
              *ngIf="
                masjidLessonsInfo.controls['lessonLanguage'].hasError('pattern')
              "
            >
              {{ t('lessonLanguageLabel') + ' ' + t('errorIsInvalid') }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <mat-form-field
            appearance="outline"
            floatLabel="always"
            class="full-width"
          >
            <mat-label>{{ t('lessonDurationLabel') }}</mat-label>
            <input
              matInput
              type="text"
              formControlName="lessonDuration"
              readonly
            />

            <mat-error
              *ngIf="
                masjidLessonsInfo.controls['lessonDuration'].hasError('pattern')
              "
            >
              {{ t('lessonDurationLabel') + ' ' + t('errorIsInvalid') }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <mat-form-field
            appearance="outline"
            floatLabel="always"
            class="full-width"
          >
            <mat-label>{{ t('lessonStartLabel') }}</mat-label>
            <input
              matInput
              [ngxTimepicker]="lessonStart"
              [format]="24"
              [ngxMaterialTimepickerTheme]="timepickerTheme"
              formControlName="lessonStart"
              readonly
            />
            <mat-icon matSuffix (click)="lessonStart.open()">schedule</mat-icon>
            <ngx-material-timepicker
              #lessonStart
              (timeSet)="updateDuration($event, 'start')"
            ></ngx-material-timepicker>
            <mat-error
              *ngIf="
                masjidLessonsInfo.controls['lessonStart'].hasError('pattern')
              "
            >
              {{ t('lessonStartLabel') + ' ' + t('errorIsInvalid') }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <mat-form-field
            appearance="outline"
            floatLabel="always"
            class="full-width"
          >
            <mat-label>{{ t('lessonEndLabel') }}</mat-label>
            <input
              matInput
              [ngxTimepicker]="lessonEnd"
              [format]="24"
              [ngxMaterialTimepickerTheme]="timepickerTheme"
              formControlName="lessonEnd"
              readonly
            />
            <mat-icon matSuffix (click)="lessonEnd.open()">schedule</mat-icon>
            <ngx-material-timepicker
              #lessonEnd
              (timeSet)="updateDuration($event, 'end')"
            ></ngx-material-timepicker>
            <mat-error
              *ngIf="
                masjidLessonsInfo.controls['lessonEnd'].hasError('pattern')
              "
            >
              {{ t('lessonEndLabel') + ' ' + t('errorIsInvalid') }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <mat-form-field
            appearance="outline"
            floatLabel="always"
            class="full-width"
          >
            <mat-label>{{ t('lessonInstructorLabel') }}</mat-label>
            <input
              matInput
              type="text"
              placeholder="{{ t('lessonInstructorLabel') }}"
              formControlName="lessonInstructor"
            />

            <mat-error
              *ngIf="
                masjidLessonsInfo.controls['lessonInstructor'].hasError(
                  'pattern'
                )
              "
            >
              {{ t('lessonInstructorLabel') + ' ' + t('errorIsInvalid') }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <mat-form-field
            appearance="outline"
            floatLabel="always"
            class="full-width"
          >
            <mat-label>{{ t('lessonDaysLabel') }}</mat-label>
            <mat-select formControlName="lessonDays" multiple>
              <mat-option
                *ngFor="let day of t('days', { array: true })"
                [value]="day"
              >
                {{ day }}
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="
                masjidLessonsInfo.controls['lessonDays'].hasError(
                  'atLeastNDays'
                )
              "
            >
              {{ t('lessonDaysLabel') + ' ' + t('errorIsInvalid') }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <input type="hidden" formControlName="lessonId" />
      <input type="hidden" formControlName="masjid_id" />
    </mat-card-content>
    <mat-card-actions
      class="flex-center full-width"
      style="padding-bottom: 1.5rem"
    >
      <div class="row">
        <div class="col flex-center">
          <button mat-raised-button (click)="addToTable()">Add to Table</button>
          <button mat-raised-button (click)="clearTable()">Clear table</button>
        </div>
      </div>
      <div class="row">
        <div class="col flex-center">
          <button
            type="button"
            mat-raised-button
            color="warn"
            (click)="doChangeStep(StepDirection.Back)"
          >
            {{ t('back') }}
          </button>
        </div>
        <div class="col flex-center">
          <button
            [disabled]="isSubmittingSave$ | async"
            type="button"
            mat-raised-button
            color="accent"
            (click)="doChangeStep(StepDirection.Finish)"
          >
            {{ t('finish') }}
          </button>
        </div>
      </div>
    </mat-card-actions>
  </mat-card>
</ng-container>

<table
  mat-table
  [dataSource]="dataSource"
  class="mat-elevation-z8"
  *transloco="let t; read: 'masjidData'"
>
  <ng-content></ng-content>
  <!-- name Column -->
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ t('LessonsTableColumnsNames.name') }}
    </th>
    <td mat-cell *matCellDef="let element">{{ element.name }}</td>
  </ng-container>

  <!-- language Column -->
  <ng-container matColumnDef="language">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ t('LessonsTableColumnsNames.language') }}
    </th>
    <td mat-cell *matCellDef="let element">{{ element.language }}</td>
  </ng-container>

  <!-- Color Column -->
  <ng-container matColumnDef="duration">
    <th mat-header-cell *matHeaderCellDef>
      {{ t('LessonsTableColumnsNames.duration') }}
    </th>
    <td mat-cell *matCellDef="let element">{{ element.duration }}</td>
  </ng-container>

  <!-- Start Column -->
  <ng-container matColumnDef="start">
    <th mat-header-cell *matHeaderCellDef>
      {{ t('LessonsTableColumnsNames.start') }}
    </th>
    <td mat-cell *matCellDef="let element">{{ element.start }}</td>
  </ng-container>

  <!-- End Column -->
  <ng-container matColumnDef="end">
    <th mat-header-cell *matHeaderCellDef>
      {{ t('LessonsTableColumnsNames.end') }}
    </th>
    <td mat-cell *matCellDef="let element">{{ element.end }}</td>
  </ng-container>

  <!-- Instructor Column -->
  <ng-container matColumnDef="instructor">
    <th mat-header-cell *matHeaderCellDef>
      {{ t('LessonsTableColumnsNames.instructor') }}
    </th>
    <td mat-cell *matCellDef="let element">{{ element.instructor }}</td>
  </ng-container>

  <!-- Days Column -->
  <ng-container matColumnDef="days">
    <th mat-header-cell *matHeaderCellDef>
      {{ t('LessonsTableColumnsNames.days') }}
    </th>
    <td mat-cell *matCellDef="let element">{{ element.days.join(', ') }}</td>
  </ng-container>

  <!-- Delete Column -->
  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef>
      {{ t('LessonsTableColumnsNames.actions') }}
    </th>
    <td mat-cell *matCellDef="let row">
      <div class="row">
        <div class="col flex-center">
          <button mat-icon-button color="primary" (click)="editItem(row)">
            <mat-icon>edit</mat-icon>
          </button>
          <button mat-icon-button color="warn" (click)="removeItem(row)">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </div>
    </td>
  </ng-container>

  <!-- Custom row definitions to be provided to the wrapper table. -->
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

  <!-- Row shown when there is no matching data that will be provided to the wrapper table. -->
  <tr class="mat-row" *matNoDataRow>
    <td class="mat-cell" colspan="4">No data</td>
  </tr>
</table>
